import type { ChangeEvent } from 'react';
import md5 from 'md5';

import type { FC } from '../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal, getGlobal } from '../../global';
import type { GlobalState } from '../../global/types';

import { IS_SAFARI } from '../../util/environment';
import { pick } from '../../util/iteratees';
import { Toast, getDefaultAppInfo, getParamsByUrl, openInstallTransfer } from '../../util/toolUtils';
import useLang from '../../hooks/useLang';

import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import InputText from '../ui/InputText';
import Loading from '../ui/Loading';
import { SHOW_LOGOUT } from '../../config';
import { LangCode, SettingsScreens } from '../../types';
import { getSuggestedLanguage } from './helpers/getSuggestedLanguage';
import { userLogin, HttpRquestUrl, userAutoRegister } from '../../global/http/api';
import { ErrorMsgMap } from '../../global/http/useErrorMessage';
import LogoImage from '../../assets/logo.png';
import loginTopBg from '../../assets/login_top.png';
import loginBottomBg from '../../assets/login_bottom.png';
type StateProps = Pick<GlobalState, (
  'authState' |
  'authPrevState' |
  'authIsLoading' |
  'authError' |
  'authRememberMe'
)> & {
  forceInviteCode?: string;
  language: string;
};
export interface AppInfoProps {
  merchantLogoUrl: string;
  merchantName: string;
  autofillInviteCode: string;
  isAutofillInviteCode: number;
  language: string;
}
const AuthUserNameLogin: FC<StateProps> = ({
  authState,
  authPrevState,
  authIsLoading,
  authError,
  authRememberMe,
  forceInviteCode,
  language,
}) => {
  const {
    returnToAuthPhoneNumber,
    goToAuthSignUp,
    goToAuthSetLanguage,
    setAuthUserNameLogin,
    setAuthRememberMe,
    clearAuthError,
    setToken,
    showNotification,
    setSettingsScreenType,
    setTemporaryInviteCode,
    setTemporaryRecommendCode,
    goToAuthVerifyLogin,
  } = getActions();
  const urlInviteCode = getParamsByUrl('inviteCode') || '';
  const lang = useLang();
  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  const inviteInputRef = useRef<HTMLInputElement>(null);
  const referralInputRef = useRef<HTMLInputElement>(null);

  const [userName, setUserName] = useState<string | ''>();
  const [password, setPassword] = useState<string | ''>();
  const [lastSelection, setLastSelection] = useState<[number, number] | undefined>();

  const [nowTemporaryInviteCode, setNowTemporaryInviteCode] = useState('');
  const [nowTemporaryReferralCode, setNowTemporaryReferralCode] = useState('');
  const [isRightInviteCode, setIsRightInviteCode] = useState(true);
  const [isRightReferralCode, setIsRightReferralCode] = useState(true);
  const [appInfo, setAppInfo] = useState<AppInfoProps>();
  const [loadingRegister, setLoadingRegister] = useState<boolean | undefined>(true);
  // 下一步按钮的显示和隐藏判断
  const canSubmit = userName && password;
  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');

    if (urlInviteCode && !loginInfo) {
      setLoadingRegister(true);
      userAutoRegister({ inviteCode: urlInviteCode }, handleErrorMsg).then((res: boolean | undefined) => {
        setLoadingRegister(res);

        if (window && window.Enterprise_SDK && window.Enterprise_SDK.effectPoint) {
          window.Enterprise_SDK.effectPoint('login');
        } else if (window.webkit && window.webkit.messageHandlers.Enterprise_SDK) {
          window.webkit.messageHandlers.Enterprise_SDK.postMessage({ type: "effectPoint", payload: { pointId: 'login' } });
        }
        // const params = {
        //   inviteCode: urlInviteCode,
        //   DeviceType: getParamsByUrl('DeviceType') || '',
        //   DeviceModel: getParamsByUrl('DeviceModel') || '',
        //   BrowserVersion: getParamsByUrl('BrowserVersion') || '',
        //   route: getParamsByUrl('route') || '',
        //   channelCode: getParamsByUrl('channelCode') || ''
        // }
        // openInstallTransfer(params);
      });
      // setInviteCode(urlInviteCode);
    } else {
      setLoadingRegister(false);
    }
  }, [urlInviteCode]);
  useEffect(() => {
    const gAppInfo = localStorage.getItem('appInfo');
    const languageValue = language || localStorage.getItem('lang');
    if (gAppInfo) {
      const gAppInfoMap = JSON.parse(gAppInfo);
      const showAppInfo: AppInfoProps = {
        merchantLogoUrl: gAppInfoMap.merchantLogoUrl,
        merchantName: '',
        autofillInviteCode: gAppInfoMap.autofillInviteCode,
        isAutofillInviteCode: gAppInfoMap.isAutofillInviteCode,
      };
      switch (languageValue) {
        case 'classic-zh-cn':
          showAppInfo.merchantName = gAppInfoMap.merchantName;
          break;
        case 'zh-hant-raw':
          showAppInfo.merchantName = gAppInfoMap.merchantNameZhTra;
          break;
        case 'en':
          showAppInfo.merchantName = gAppInfoMap.merchantNameEn;
          break;
        default:
          showAppInfo.merchantName = gAppInfoMap.merchantName;
      }
      setAppInfo(showAppInfo);
    } else {
      setDefaultAppInfo();
    }
  }, [language]);
  useLayoutEffect(() => {
    if (inputRef.current && lastSelection) {
      inputRef.current.setSelectionRange(...lastSelection);
    }
  }, [lastSelection]);
  // 是否是刚刚粘贴进去
  const isJustPastedRef = useRef(false);
  // 手动粘贴
  const handlePaste = useCallback(() => {
    isJustPastedRef.current = true;
    requestAnimationFrame(() => {
      isJustPastedRef.current = false;
    });
  }, []);
  const setDefaultAppInfo = async () => {
    const defaultAppInfo = await getDefaultAppInfo(window.location.host);
    setAppInfo(defaultAppInfo);
  };
  const handleKeyDown = (event) => {
    // 阻止空格键的默认行为
    if (event.key === ' ') {
      event.preventDefault();
    }
  };
  // 用户名输入框出现变化
  const handleUserNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    const { value } = e.target;
    setUserName(value);
  }, [authError, clearAuthError]);

  // 登录密码输入框出现变化
  const handlePasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    const { value } = e.target;
    setPassword(value);
  }, [authError, clearAuthError]);

  // 邀请码输入框出现变化
  const handleInviteCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNowTemporaryInviteCode(value);
    setIsRightInviteCode(true);
  }, []);

  // 推荐码输入框出现变化
  const handleReferralCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNowTemporaryReferralCode(value);
    setIsRightReferralCode(true);
  }, []);

  const handleKeepSessionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAuthRememberMe(e.target.checked);
  }, [setAuthRememberMe]);
  const handleErrorMsg = useCallback((result: any) => {
    const key = ErrorMsgMap[result.code];
    if (key) {
      Toast(lang(key) as string, 2000);
    } else {
      Toast(result.msg, 2000);
    }
  }, []);
  // 提交请求
  async function handleSubmit(event: React.FormEvent<HTMLButtonElement>) {
    event.preventDefault();
    await inputRef.current?.blur();
    if (authIsLoading) {
      return;
    }
    if (canSubmit) {
      if (SHOW_LOGOUT) {
        if (window && window.Enterprise_SDK && window.Enterprise_SDK.getPublicIpByThread) {
          window.Enterprise_SDK.getPublicIpByThread();
        }
        const params = {
          username: userName,
          password: md5(password),
        };
        await userLogin(HttpRquestUrl.USER_LOGIN, params, handleErrorMsg).then((res) => {
          console.log(res);
          if (window && window.Enterprise_SDK && window.Enterprise_SDK.effectPoint) {
            window.Enterprise_SDK.effectPoint('login');
          } else if (window.webkit && window.webkit.messageHandlers.Enterprise_SDK) {
            window.webkit.messageHandlers.Enterprise_SDK.postMessage({ type: "effectPoint", payload: { pointId: 'login' } });
          }
        });
      } else {
        // submitForm()
        setAuthUserNameLogin({ userName, password: md5(password) });
      }
    }
  }

  // // 提交表单
  // const submitForm = useCallback(async () => {
  //   // 邀请码处理
  //   if (forceInviteCode === '1') {
  //     let isSuccess = await checkInviteCode(userName, nowTemporaryInviteCode)
  //     if (isSuccess) {
  //       setAuthUserNameLogin({ userName, password: md5(password) })
  //     }
  //   }

  //   // 推荐码处理
  //   else if (forceInviteCode === '2') {
  //     let isSuccess = await checkReferralCode(userName, nowTemporaryReferralCode)
  //     if (isSuccess) {
  //       setAuthUserNameLogin({ userName, password: md5(password) })
  //     }
  //   }

  //   else {
  //     setAuthUserNameLogin({ userName, password: md5(password) })
  //   }
  // }, [forceInviteCode, userName, password, nowTemporaryInviteCode, nowTemporaryReferralCode])

  // 检测邀请码是否正确
  // const checkInviteCode = useCallback((name, code) => {
  //   // let isSuccess = await postForceInviteCode('', name, code)
  //   // setIsRightInviteCode(isSuccess)
  //   // isSuccess && setTemporaryInviteCode({ code: code })
  //   // return isSuccess
  // }, [postForceInviteCode, setIsRightInviteCode])

  // // 检测推荐码是否正确
  // const checkReferralCode = useCallback((name, code) => {
  //   // const isSuccess = await postForceInviteCode('', name, code);
  //   // setIsRightReferralCode(isSuccess);
  //   // isSuccess && setTemporaryRecommendCode({ code: code });
  //   // return isSuccess;
  // }, [postForceInviteCode, setIsRightReferralCode]);

  const isAuthReady = authState === 'authorizationStateUserNameLogin';

  useEffect(() => {
    // 初始化結束再将 browserID 插入到global中
    if (isAuthReady) {
      // getBrowserID();
    }
  }, [isAuthReady]);

  const divStyleCss = `
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 18px;
    color: #f00;
    line-height: 1;
  `;

  const pLinkStyleCss = `
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
    text-align: left;
    font-size: 1rem;
    color: var(--color-primary);
    cursor: pointer;
  `;
  let logoBgCss = '';
  if (appInfo && appInfo.merchantLogoUrl) {
    logoBgCss = `background-image: url(${appInfo.merchantLogoUrl})`;
  } else {
    logoBgCss = `background-image: url(${LogoImage})`;
  }
  return (
    <div id="auth-phone-number-form" className="custom-scroll auth-user-info-form-background">
      {/* <img className="logo-top-bg" src={loginTopBg} alt="" />
      <img className="logo-bottom-bg" src={loginBottomBg} alt="" /> */}
      {
        !loadingRegister
          ? (
            <>

              <div className="set-language" onClick={() => goToAuthSetLanguage({ authState: 'authorizationStateSetLanguage' })}>{lang('lng_language_name')}</div>
              <div className="auth-form auth-form-custom">
                {/* <div id="logo" className="logo" style={logoBgCss} /> */}
                <h1 className="page-title">{lang('WelcomeLogin')}</h1>
                {/* <h1>
                  {appInfo && appInfo.merchantName}
                </h1> */}
                <p className="note">{lang('StartTextLogin')}</p>
                <div>
                  <InputText
                    ref={inputRef}
                    id="sign-in-phone-number"
                    label={lang('YourUserName')}
                    // label={'Your user name'}
                    value={userName}
                    error={authError && lang(authError)}
                    inputMode="text"
                    onChange={handleUserNameChange}
                    onPaste={IS_SAFARI ? handlePaste : undefined}
                    onKeyDown={handleKeyDown}
                  />
                  <InputText
                    typeMode="password"
                    ref={inputRef}
                    id="sign-in-phone-number"
                    // label={'Login Password'}
                    label={lang('LoginPassword')}
                    value={password}
                    error={authError && lang(authError)}
                    inputMode="text"
                    onChange={handlePasswordChange}
                    onPaste={IS_SAFARI ? handlePaste : undefined}
                    onKeyDown={handleKeyDown}
                  />

                  {
                    (forceInviteCode === '0' || forceInviteCode === '1')
                      ? (
                        // Invite code 邀请码
                        <InputText
                          ref={inviteInputRef}
                          id="sign-in-invite-code"
                          label={forceInviteCode === '0' ? lang('InviteCodeOptional') : lang('InviteCodeRequired')}
                          value={nowTemporaryInviteCode}
                          // error={authError && lang(authError)}
                          inputMode="text"
                          onChange={handleInviteCodeChange}
                          onPaste={IS_SAFARI ? handlePaste : undefined}
                          onKeyDown={handleKeyDown}
                        />
                      )
                      : (forceInviteCode === '2'
                        ? (
                          // Referral Code 推荐码
                          <InputText
                            ref={referralInputRef}
                            id="sign-in-referral-code"
                            // label={'Referral code (Required filling）'}
                            label={lang('ReferralCodeRequired')}
                            value={nowTemporaryReferralCode}
                            // error={authError && lang(authError)}
                            inputMode="text"
                            onChange={handleReferralCodeChange}
                            onPaste={IS_SAFARI ? handlePaste : undefined}
                            onKeyDown={handleKeyDown}
                          />
                        )
                        : undefined
                      )
                  }

                  {
                    !isRightInviteCode
                      ? (
                        <div style={divStyleCss}>
                          {/* 待翻译 */}
                          {/* Invite code absence! */}
                          {lang('InviteCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }

                  {
                    !isRightReferralCode
                      ? (
                        <div style={divStyleCss}>
                          {/* 待翻译 */}
                          {/* Referral code absence! */}
                          {lang('ReferralCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }
                  {
                    isAuthReady
                      ? <p style={pLinkStyleCss} onClick={goToAuthSignUp}>{lang('NewRegistration')}</p>
                      : <Loading />
                  }
                  {/*
          <Checkbox
            id="login-keep-session"
            // label="Keep me signed in"
            label={lang('KeepMeSignedIn')}
            checked={Boolean(authRememberMe)}
            onChange={handleKeepSessionChange}
          /> */}
                  <Button
                    type="submit"
                    ripple
                    isLoading={authIsLoading}
                    disabled={!isAuthReady || !canSubmit}
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  >{lang('Login.Button')}
                  </Button>
                  {/* {
            canSubmit && (
              isAuthReady ? (
                <Button
                  type="submit"
                  ripple
                  isLoading={authIsLoading}
                  onClick={(event)=> {
                    handleSubmit(event)
                  }}>{lang('Login.Button')}</Button>
              ) : (
                <Loading />
              )
            )
          } */}
                  {/* {isAuthReady ? (
            <Button isText onClick={returnToAuthPhoneNumber}>{lang('Login.QR.Cancel')}</Button>
          ) : <Loading />} */}
                  {/* {suggestedLanguage && suggestedLanguage !== language && continueText && (
            <Button isText isLoading={isLoading} onClick={handleLangChange}>{continueText}</Button>
          )} */}
                </div>
              </div>
            </>
          )
          : <Loading />
      }

    </div>
  );
};

export default memo(withGlobal(
  (global): StateProps => {
    const {
      forceInviteCode,
      authPrevState,
    } = global;
    const language = global.settings.byKey.language;
    return {
      ...pick(global, [
        'authState',
        'authIsLoading',
        'authError',
        'authRememberMe',
        'authPrevState',
      ]),
      forceInviteCode,
      language,
    };
  },
)(AuthUserNameLogin));
