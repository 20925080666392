import { loginCallback } from "../../components/auth/AuthUserNameSignUp";
import { RQUEST_URL } from "../../config";
import { getActions, getGlobal } from "../../global";
import { Toast, clearLocalStorage, getParamsByUrl, sendMessageToNativeOrIframe } from "../../util/toolUtils";
type ErrorMsgMapProps = {
    string: string
}
export const ErrorMsgMap = {
}
const BaseUrl: string = RQUEST_URL;
let global = getGlobal();
let verify_InterVal: any = null;
export interface ResponseProps {
    code: number | string;
    data: object;
    msg: string
}
export interface LoginResponseProps {
    tgid: number | undefined,
    imToken: string,
    authorization: string,
}
export const HttpRquestUrl = {
    USER_LOGIN: 'account/login',
    USER_REGISTER: 'account/register',
    DISCOVER_LIST: '/invite/inviteCodeDiscoveryList',
    USER_CHANGE_PASSWORD: 'account/changeUserPassword',
    USER_REFRESH_TOKEN: 'account/refreshToken',
    QUERY_MERCHANTINFO: 'account/getMerchantInfo',
    QUERY_USERINFO: 'account/getUserInfo',
    REQUEST_RESULT_LOGIN: 'account/getLoginRequestResult',
    AUTO_REGISTER: 'account/autoRegister',
    PROXY_GET_DOMAIN_INFO: 'update/getDomainInfo',
    PROXY_INFO: 'update/getProxyInfo',
    PROXY_CHECK_HEATH: 'checkHeath',
    PROXY_LAST_UPDATE_INFO: 'update/getLastUpdateInfo'

}
const getDeviceInfo = () => {
    const userAgent: any = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();
    let environment = 5; // 默认为 PC 网页
    let deviceModel = "--";
    let browserVersion = "--";

    // 判断是否是移动端
    if (/android/.test(userAgent)) {
        if (/mobile/.test(userAgent)) {
            environment = 1; // 安卓端
            const modelMatch = userAgent.match(/; ([^;]+) build/);
            deviceModel = modelMatch ? modelMatch[1].trim() : "Android Device";
        } else {
            environment = 2; // 安卓网页
            deviceModel = "Android Device";
        }
    } else if (/iphone/.test(userAgent)) {
        if (/mobile/.test(userAgent)) {
            environment = 3; // iOS端
            deviceModel = "iPhone"; // 默认 iPhone，无需解析 userAgent
        } else {
            environment = 4; // iOS网页
            deviceModel = "iPhone";
        }
    } else if (/ipad/.test(userAgent)) {
        environment = 4; // iOS网页（iPad 视为 iOS 网页）
        deviceModel = "iPad";
    } else if (/macintosh|windows/.test(platform)) {
        environment = 5; // PC 网页
        deviceModel = /macintosh/.test(platform) ? "Mac" : "Windows PC";
    }

    // 提取浏览器名称和版本
    if (/chrome/.test(userAgent) && !/edg/.test(userAgent)) {
        const version = userAgent.match(/chrome\/([\d.]+)/)[1];
        browserVersion = `Chrome ${version}`;
    } else if (/firefox/.test(userAgent)) {
        const version = userAgent.match(/firefox\/([\d.]+)/)[1];
        browserVersion = `Firefox ${version}`;
    } else if (/safari/.test(userAgent) && !/chrome/.test(userAgent)) {
        const version = userAgent.match(/version\/([\d.]+)/)[1];
        browserVersion = `Safari ${version}`;
    } else if (/edg/.test(userAgent)) {
        const version = userAgent.match(/edg\/([\d.]+)/)[1];
        browserVersion = `Edge ${version}`;
    } else if (/msie/.test(userAgent) || /trident/.test(userAgent)) {
        const version = userAgent.match(/(?:msie |rv:)([\d.]+)/)[1];
        browserVersion = `Internet Explorer ${version}`;
    }


    return {
        environment, // 登录环境：1=安卓端, 2=安卓网页, 3=iOS端, 4=iOS网页, 5=PC网页
        deviceModel, // 设备型号
        browserVersion // 浏览器版本号
    };
};

// 调用函数
const deviceInfo = getDeviceInfo();
const iPAddress = localStorage.getItem('iPAddress');
const handleResponseInterceptor = (result: any, callback: ((errorCode: string) => void) | undefined, handleRefreshCallback: () => void) => {
    if (result && result.code == '100003') {
        userRefreshToken(handleRefreshCallback);
    } else {
        callback && callback(result);
    }
}
export async function checkTokenIsExpiration(): Promise<boolean> {
    const token = checkLoginAuthorization();
    if (!token) return false;
    const response = await fetch(BaseUrl + HttpRquestUrl.DISCOVER_LIST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
        }
    });
    const result = await response.json();

    return new Promise((resolve) => {
        if (result.code === 200) {
            resolve(true);
        } else if (result.code == '100003') {
            resolve(false);
        } else {
            return (false);
        }
    })
}
const checkLoginAuthorization = () => {
    if (global.token) {
        return global.token;
    } else {
        const loginInfo = localStorage.getItem("loginInfo");
        if (Boolean(loginInfo)) {
            const loginObj = JSON.parse(loginInfo) as LoginResponseProps;
            getActions().setToken({ token: loginObj.authorization });
            return loginObj.authorization;
        }
        return undefined;
    }
}
export async function queryMerchantInfo(callback: { (result: any): void; (arg0: any): any; }) {
    let params = {
        merchantDomain: window.location.host
        // merchantDomain: 'www.91wsapp.com'
        // merchantDomain: 'www.flychat.com'
    };
    const response = await fetch(BaseUrl + HttpRquestUrl.QUERY_MERCHANTINFO, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        callback && callback(result.data);
    }
}
/**
 * query discover list
 * */
export async function queryDiscoverList() {
    getActions().setLoadingData({ isLoadingData: true });
    const token = checkLoginAuthorization();
    if (!token) return;
    const response = await fetch(BaseUrl + HttpRquestUrl.DISCOVER_LIST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
        }
    });
    const result = await response.json();
    if (result.code === 200) {
        getActions().setLoadingData({ isLoadingData: false });
        getActions().setDiscoveryList({ discoverList: result.data.list || [] });
    } else if (result.code == '100003') {
        userRefreshToken(() => {
            queryDiscoverList();
        });
    } else {
        Toast(result.msg, 2000);
    }
}
export async function getUserInfo(): Promise<any> {
    const token = checkLoginAuthorization();
    if (!token) return false;
    const response = await fetch(BaseUrl + HttpRquestUrl.QUERY_USERINFO, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
        }
    });
    const result = await response.json();
    return new Promise((resolve) => {
        if (result.code === 200) {
            const loginInfo = localStorage.getItem("loginInfo");
            if (loginInfo) {
                let loginInfoMap = JSON.parse(loginInfo);
                loginInfoMap.imToken = result.data.imToken;
                localStorage.setItem("loginInfo", JSON.stringify(loginInfoMap));
            }
            resolve(result.data.imToken);
        } else if (result.code == '100003') {
            resolve(false);
        } else {
            return (false);
        }
    })
}
export async function userChangePassword(requrstUrl: string, params: { userId: string, oldPassword: string, newPassword: string }, errCallback: ((errorCode: string) => void) | undefined) {
    const token = checkLoginAuthorization();
    if (!token) return;
    const response = await fetch(BaseUrl + requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token,
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    handleResponseInterceptor(result, errCallback, () => {
        userChangePassword(requrstUrl, params, errCallback);
    });

}
export async function userLogin(requrstUrl: string, params: { username: string, password: string }, callback: ((errorCode: string) => void) | undefined) {
    const urlInviteCode = getParamsByUrl('inviteCode') || '';
    const urlDeviceType = getParamsByUrl('DeviceType') || '';
    const urlDeviceModel = getParamsByUrl('DeviceModel') || '';
    const urlBrowserVersion = getParamsByUrl('BrowserVersion') || '';
    const urlRoute = getParamsByUrl('route') || '';
    const urlChannelCode = getParamsByUrl('channelCode') || '';
    const urluuid = getParamsByUrl('DeviceId') || '';
    const urliPAddress = getParamsByUrl('iPAddress') || '';
    const response = await fetch(BaseUrl + requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
            'DeviceType': urlDeviceType || String(deviceInfo.environment),
            'DeviceModel': urlDeviceModel || deviceInfo.deviceModel,
            'BrowserVersion': urlBrowserVersion || deviceInfo.browserVersion,
            'DeviceId': urluuid,
            'ChannelCode': urlChannelCode,
            'iPAddress': iPAddress || urliPAddress || ''
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        // getActions.setLoading();
        if (result.data.state === 1) {
            verify_InterVal = setInterval(() => {
                getLoginRequestResult({ sessionId: result.data.sessionId });
            }, 1000);
        } else if (result.data.state === 0) {
            const params = {
                userId: result.data.id,
                tgid: result.data.imUserId,
                imToken: result.data.imToken,
                inviteCode: result.data.inviteCode,
                authorization: result.data.token,
                refreshToken: result.data.refreshToken,
                isLogin: true,
                redpacketToken: result.data.redpacketToken
            }
            localStorage.setItem("loginInfo", JSON.stringify(params));
            window.location.reload();
        }
    } else {
        callback && callback(result);
    }
}
export async function userRefreshToken(handleRefreshCallback: (() => void) | undefined) {
    const loginInfo = localStorage.getItem("loginInfo");
    if (loginInfo) {
        const loginInfoMap = JSON.parse(loginInfo);
        const refreshToken = loginInfoMap.refreshToken;
        let params = {
            refreshToken
        };
        const response = await fetch(BaseUrl + HttpRquestUrl.USER_REFRESH_TOKEN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
            },
            body: JSON.stringify(params)
        });
        const result = await response.json();
        if (result.code === 200) {
            let newLoginInfoMap = {
                ...loginInfoMap,
                authorization: result.data.token,
                refreshToken: result.data.refreshToken,
                redpacketToken: result.data.redpacketToken
            }
            localStorage.setItem("loginInfo", JSON.stringify(newLoginInfoMap));
            handleRefreshCallback && handleRefreshCallback();
        } else {
            getActions().signOut();
            clearLocalStorage();
            window.location.reload();
        }
    }
}
export async function userRegister(requrstUrl: string, params: { username: string, password: string }, errCallback: { (result: any): void; (arg0: { username: string; password: string; }): any; }, callback: any) {
    const urlInviteCode = getParamsByUrl('inviteCode') || '';
    const urlDeviceType = getParamsByUrl('DeviceType') || '';
    const urlDeviceModel = getParamsByUrl('DeviceModel') || '';
    const urlBrowserVersion = getParamsByUrl('BrowserVersion') || '';
    const urlRoute = getParamsByUrl('route') || '';
    const urlChannelCode = getParamsByUrl('channelCode') || '';
    const urluuid = getParamsByUrl('DeviceId') || '';
    const urliPAddress = getParamsByUrl('iPAddress') || '';
    const response = await fetch(BaseUrl + requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
            'DeviceType': urlDeviceType || String(deviceInfo.environment),
            'DeviceModel': urlDeviceModel || deviceInfo.deviceModel,
            'BrowserVersion': urlBrowserVersion || deviceInfo.browserVersion,
            'DeviceId': urluuid,
            'ChannelCode': urlChannelCode,
            'iPAddress': iPAddress || urliPAddress || ''
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();

    if (result.code === 200) {
        const params = {
            userId: result.data.id,
            tgid: result.data.imUserId,
            imToken: result.data.imToken,
            inviteCode: result.data.inviteCode,
            authorization: result.data.token,
            refreshToken: result.data.refreshToken,
            isLogin: true,
            redpacketToken: result.data.redpacketToken
        }
        callback && callback(params);
        localStorage.setItem("loginInfo", JSON.stringify(params));
        window.location.reload();
    } else {
        errCallback && errCallback(result);
    }
}
export async function getLoginRequestResult(params: { sessionId: string }) {

    const urlInviteCode = getParamsByUrl('inviteCode') || '';
    const urlDeviceType = getParamsByUrl('DeviceType') || '';
    const urlDeviceModel = getParamsByUrl('DeviceModel') || '';
    const urlBrowserVersion = getParamsByUrl('BrowserVersion') || '';
    const urlRoute = getParamsByUrl('route') || '';
    const urlChannelCode = getParamsByUrl('channelCode') || '';
    const urluuid = getParamsByUrl('DeviceId') || '';
    const urliPAddress = getParamsByUrl('iPAddress') || '';
    const response = await fetch(BaseUrl + HttpRquestUrl.REQUEST_RESULT_LOGIN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
            'DeviceType': urlDeviceType || String(deviceInfo.environment),
            'DeviceModel': urlDeviceModel || deviceInfo.deviceModel,
            'BrowserVersion': urlBrowserVersion || deviceInfo.browserVersion,
            'DeviceId': urluuid,
            'iPAddress': iPAddress || urliPAddress || ''
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        getActions().goToAuthVerifyLogin({ authState: 'authorizationStateVerifyLogin', verifyState: result.data.state });
        if (result.data.state === 2) {
            const params = {
                userId: result.data.id,
                tgid: result.data.imUserId,
                imToken: result.data.imToken,
                inviteCode: result.data.inviteCode,
                authorization: result.data.token,
                refreshToken: result.data.refreshToken,
                isLogin: true,
                redpacketToken: result.data.redpacketToken
            }
            localStorage.setItem("loginInfo", JSON.stringify(params));
            window.location.reload();
        } else if (result.data.state === 3) {
            clearInterval(verify_InterVal);
            return false;
        }
    }
}
export async function userAutoRegister(params: { inviteCode: string }, callback: ((errorCode: string) => void) | undefined) {

    const urlInviteCode = getParamsByUrl('inviteCode') || '';
    const urlDeviceType = getParamsByUrl('DeviceType') || '';
    const urlDeviceModel = getParamsByUrl('DeviceModel') || '';
    const urlBrowserVersion = getParamsByUrl('BrowserVersion') || '';
    const urlRoute = getParamsByUrl('route') || '';
    const urlChannelCode = getParamsByUrl('channelCode') || '';
    const urluuid = getParamsByUrl('DeviceId') || '';
    const urliPAddress = getParamsByUrl('iPAddress') || '';
    const response = await fetch(BaseUrl + HttpRquestUrl.AUTO_REGISTER, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
            'DeviceType': urlDeviceType || String(deviceInfo.environment),
            'DeviceModel': urlDeviceModel || deviceInfo.deviceModel,
            'BrowserVersion': urlBrowserVersion || deviceInfo.browserVersion,
            'DeviceId': urluuid,
            'ChannelCode': urlChannelCode,
            'iPAddress': iPAddress || urliPAddress || ''
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        const params = {
            userId: result.data.id,
            tgid: result.data.imUserId,
            imToken: result.data.imToken,
            inviteCode: result.data.inviteCode,
            authorization: result.data.token,
            refreshToken: result.data.refreshToken,
            isLogin: true,
            redpacketToken: result.data.redpacketToken
        }
        localStorage.setItem("loginInfo", JSON.stringify(params));
        // window.location.reload();

        // 获取当前 URL 的查询参数
        let url = new URL(window.location.href);

        // 使用 URLSearchParams 解析查询字符串
        let params1 = new URLSearchParams(url.search);

        // 删除特定的查询参数，比如 'param1'
        params1.delete('inviteCode');

        // 构造新的 URL
        url.search = params1.toString();

        window.location.href = url.toString();
        return true;
    } else if (result.code === 103003) {
        callback && callback(result);
    } else {
        return false;
    }
}

export async function userProxyWebviewDomainInfo() {
    const response = await fetch(BaseUrl + HttpRquestUrl.PROXY_GET_DOMAIN_INFO, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
    });
    const result = await response.json();
    if (result) {
        return result;
    }
    return undefined;

}
export async function userProxyWebviewInfo() {
    const response = await fetch(BaseUrl + HttpRquestUrl.PROXY_INFO, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
    });
    const result = await response.json();

    if (result) {
        return result;
    }
    return undefined;
}
export async function userProxyWebviewCheckHeath() {
    const response = await fetch(BaseUrl + HttpRquestUrl.PROXY_CHECK_HEATH, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
    });
    const result = await response.json();

    if (result) {
        return result;
    }
    return undefined;
}
export async function userProxyWebviewLastUpdateInfo() {
    const response = await fetch(BaseUrl + HttpRquestUrl.PROXY_LAST_UPDATE_INFO, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
    });
    const result = await response.json();

    if (result) {
        return result;
    }
    return undefined;
}
