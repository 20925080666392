import bigInt from 'big-integer';
import type { FC } from './lib/teact/teact';
import React, {
  useEffect, useCallback, useLayoutEffect, useRef, useState,
} from './lib/teact/teact';
import { getActions, withGlobal, getGlobal } from './global';
import { fetchChatByUsernameJoinGlobal } from './global/actions/api/chats';

import { selectChat, selectUser } from './global/selectors';
import type { GlobalState } from './global/types';
import type { CustomerList, LangCode, ThemeKey } from './types';
import type { UiLoaderPage } from './components/common/UiLoader';
/* import {
  postClientStartup, getToken, postForceInviteCode, getWhiteList, getBrowserID,
} from './http/request';
 */
import {
  INACTIVE_MARKER, PAGE_TITLE, WSS_URL, PARTNERID, ANIMATION_LEVEL_MAX, RE_TELEGRAM_LINK,
} from './config';
import { PLATFORM_ENV } from './util/environment';
import { updateSizes } from './util/windowSize';

import { addActiveTabChangeListener } from './util/activeTabMonitor';
import { hasStoredSession } from './util/sessions';
import buildClassName from './util/buildClassName';
import usePrevious from './hooks/usePrevious';

import Auth from './components/auth/Auth';
import Main from './components/main/Main.async';
import LockScreen from './components/main/LockScreen.async';
import AppInactive from './components/main/AppInactive';
import Transition from './components/ui/Transition';
import UiLoader from './components/common/UiLoader';
import { parseInitialLocationHash } from './util/routing';
import switchTheme from './util/switchTheme';
import { checkTokenIsExpiration, getUserInfo } from './global/http/api';
import { setLanguage } from './util/langProvider';
import {
  DomainProps, RegisterDomainLabels, checkDomainMap, clearLocalStorage, clearStorageByKey, getBottomBarHeight, getDomainMap, getParamsByUrl, isIOS, queryMerchantInfoUtil, sendMessageToNativeOrIframe, setDefaultThemes,
} from './util/toolUtils';

type StateProps = {
  canShowContacts?: boolean;
  authState: GlobalState['authState'];
  isScreenLocked?: boolean;
  hasPasscode?: boolean;
  hasWebAuthTokenFailed?: boolean;
  currentUserId: string;
  currentFirstName?: string;
  currentLastName?: string;
  currentUsername?: string;
  phone?: string;
  isLoggingOut?: boolean;
  token: string;
  forceInviteCode: string;
  isClientStartup: boolean;
  customerList: CustomerList[];
};

enum AppScreens {
  auth,
  lock,
  main,
  inactive,
}
const newIn: Array<string> = [];
const App: FC<StateProps> = ({
  canShowContacts,
  authState,
  isScreenLocked,
  hasPasscode,
  hasWebAuthTokenFailed,
  currentUserId,
  currentFirstName,
  currentLastName,
  currentUsername,
  phone,
  isLoggingOut,
  token,
  forceInviteCode,
  isClientStartup,
  customerList,
}) => {
  const {
    disconnect, joinChannel, setNewWhiteList, setBlockadeAccount,
    setCustomerList,
    // updateContact, openChat, sendMessage
  } = getActions();
  const urlInviteCode = getParamsByUrl('inviteCode') || '';

  // const [isInactive, markInactive] = useFlag(false);
  // const isMobile = PLATFORM_ENV === 'iOS' || PLATFORM_ENV === 'Android';

  // 修改构建链接websocket有多个问题，变成只有一个
  // useLayoutEffect(() => {
  //   // 只需要new一下就可以创建一个websocket的实例
  //   // 我们要去连接ws协议
  //   let ws = new WebSocket(WSS_URL + 'push/api/ws/chat/' + PARTNERID + '/?userID=' + currentUserId)

  //   // onopen是客户端与服务端建立连接后触发
  //   ws.onopen = function () {
  //     ws.send('open');
  //     console.log('open ws');
  //   };

  //   // onmessage是当服务端给客户端发来消息的时候触发
  //   ws.onmessage = async function (res) {
  //     // debugger
  //     // console.log('ws.onmessage');
  //     // // 打印的是MessageEvent对象
  //     // console.log(res);
  //     // // 真正的消息数据是 res.data
  //     // console.log(res.data);

  //     const data = JSON.parse(res.data);
  //     // console.log('wss push/api/ws/chat/ ');
  //     // console.log(data);

  //     const resultData = data.data;

  //     const newWhiteList: any = [];
  //     // 白名单相关操作
  //     if (data.websocketType === 1) {
  //       // 暂时隐藏下面的请求，这里逻辑不对，需要调整
  //       // getWhiteList(token)
  //       for (const item of resultData) {
  //         if (item.OPType === 1) {
  //           if (item.tgType === 1) {
  //             item.tgID = '' + item.tgID;
  //           } else {
  //             item.tgID = '-' + item.tgID;
  //           }
  //           newWhiteList.push(item);
  //           if (item.autoJoin) {
  //             // console.log('app--------------------------');
  //             // console.log(item.username);

  //             // 使用用户名去查询是否存在，并加入到全局中去
  //             if (item.username) {
  //               await fetchChatByUsernameJoinGlobal(item.username);
  //             }

  //             const global = getGlobal();
  //             const chat = selectChat(global, item.tgID);
  //             // 加入群和频道
  //             if (chat && item.tgType > 1) {
  //               joinChannel({ chatId: item.tgID });
  //             }
  //             // 添加联系人
  //             // if(chat && item.tgType === 1) { // 取消自动聊天
  //             //   updateContact({
  //             //     userId: item.tgID,
  //             //     firstName: '',
  //             //     lastName: '',
  //             //     shouldSharePhoneNumber: false,
  //             //   })

  //             //   openChat({id: item.tgID});
  //             //   setTimeout(() => {
  //             //     // if(!num){
  //             //       sendMessage({text:`Hi! I'm from Kyliao.`, entities:undefined , attachments:[], scheduledAt:undefined, isSilent:false})
  //             //     //   num = 1;
  //             //     // }
  //             //   }, 1000)
  //             // }
  //           }
  //         }
  //       }

  //       newWhiteList.length ? setNewWhiteList({ list: newWhiteList, isNew: true }) : ''
  //     }

  //     // 封号相关操作
  //     if (data.websocketType === 2) {
  //       resultData.forEach((item: any) => {
  //         if (item.OPType === 1 && item.userID === Number(currentUserId)) {
  //           setBlockadeAccount(item.userID === Number(currentUserId))
  //         }
  //       });
  //     }

  //     // [
  //     //   {
  //     //       "createTime": "2023-06-26:20:02:55",
  //     //       "firstName": "客户",
  //     //       "isDefault": 0,
  //     //       "isNew": 1,
  //     //       "lastName": "",
  //     //       "userID": 136819649,
  //     //       "userName": "kehu2",
  //     //       "userPhone": ""
  //     //   }
  //     // ]

  //     // 客户
  //     if (data.websocketType === 4) {
  //       // debugger
  //       if (resultData.length) {
  //         const newData = resultData.concat(customerList);
  //         setCustomerList({ list: newData });
  //       }
  //     }
  //   };

  //   ws.onclose = (e) => {
  //     console.log('close ws', e);
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, [customerList, setCustomerList])

  useEffect(() => {
    updateSizes();
    addActiveTabChangeListener(() => {
      disconnect();
      // console.log(1);
      // const appInfo = localStorage.getItem('appInfo');
      // const preparKey = window.location.host;
      // if(appInfo){
      //   const appInfoMap = JSON.parse(appInfo);
      //   if(appInfoMap.merchantDomain != preparKey){
      //     queryMerchantInfoUtil();
      //   }
      // } else {
      //   queryMerchantInfoUtil();
      // }
      // document.title = `${PAGE_TITLE}${INACTIVE_MARKER}`;
      // markInactive();
    });
    // }, [disconnect, markInactive]);
  }, [disconnect]);
  useEffect(() => {
    const appInfo = localStorage.getItem('appInfo');
    const preparKey = window.location.host;
    queryMerchantInfoUtil();
    // if(appInfo){
    //   const appInfoMap = JSON.parse(appInfo);
    //   if(appInfoMap.merchantDomain != preparKey){
    //     queryMerchantInfoUtil();
    //   } else {
    //     const domainMap = checkDomainMap(appInfoMap.merchantNameEn);
    //     let appInfo:DomainProps = {
    //       pageTitle: appInfoMap.descriptionTitle,
    //       // manifestLink: '',
    //       icons: domainMap?.icons || [],
    //       description: appInfoMap.description,
    //       manifestLink: domainMap?.manifestLink || '',
    //     }
    //     RegisterDomainLabels(appInfo);
    //   }
    // } else {
    //   queryMerchantInfoUtil();
    // }
  }, []);
  // Prevent drop on elements that do not accept it
  useEffect(() => {
    // postClientStartup();
    // console.log('app getBrowserID');

    // getBrowserID();
    const body = document.body;
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      if (!e.dataTransfer) return;
      if (!(e.target as HTMLElement).dataset.dropzone) {
        e.dataTransfer.dropEffect = 'none';
      } else {
        e.dataTransfer.dropEffect = 'copy';
      }
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
    };
    body.addEventListener('drop', handleDrop);
    body.addEventListener('dragover', handleDrag);
    body.addEventListener('dragenter', handleDrag);
    return () => {
      body.removeEventListener('drop', handleDrop);
      body.removeEventListener('dragover', handleDrag);
      body.removeEventListener('dragenter', handleDrag);
    };
  }, []);
  // if (timer) clearInterval(timer)
  // timer = setInterval(() => {
  //   console.log("###", authState)
  // },1000)
  // 登录前先进行邀请码或者推荐码校验，确保填过和填对
  // async function checkForceInviteCodeToGetToken() {
  //   const isSuccess = await postForceInviteCode(phone, currentUsername);
  //   isSuccess && getToken(currentUserId, currentUsername, currentFirstName, currentLastName, phone)
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function postChangeFirstName(name: string) {
    const userAuth = localStorage.getItem('user_auth');
    if (!userAuth) return;
    const user = getGlobal().users.byId[JSON.parse(localStorage.getItem('user_auth')!).id];
    if (!user) {
      setTimeout(() => {
        postChangeFirstName(name);
      }, 1000);
      return;
    }
    const userName = user.firstName;
    if (userName !== name) {
      setTimeout(() => {
        window.getMessageFromApp('nameChange', name);
      }, 3000);
    }
  }
  function toLogin() {
    // eslint-disable-next-line
    window.parent.postMessage({ type: 'login' }, '*');
    const userAgent = navigator.userAgent;
    const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
    if (isiOS) {
      if (window.webkit
        && typeof window.webkit === 'object'
        && window.webkit.messageHandlers
        && typeof window.webkit.messageHandlers === 'object'
        && window.webkit.messageHandlers.TGWebview
        && typeof window.webkit.messageHandlers.TGWebview === 'object'
        && window.webkit.messageHandlers.TGWebview.postMessage
        && typeof window.webkit.messageHandlers.TGWebview.postMessage === 'function'
      ) {
        window.webkit?.messageHandlers?.TGWebview.postMessage(
          {
            action: 'login',
          },
        );
      } else {
        window.parent.postMessage({ type: 'login' }, '*');
      }
    }
  }
  useEffect(() => {
    // 调用注册接口
    // if (!window.location.href.includes('ThirdLogin')) return;
    const search = window.location.search.slice(1).split('&');
    let link = '';
    let name = '';
    let imToken = '';
    let tgid = 0;
    let showMember = false;
    let createChannel = false;
    let createGroup = false;
    let addContact = false;
    for (let i = 1; i < search.length; i++) {
      const temp = search[i].split('=');
      if (temp[0] === 'link') {
        link = temp[1];
        localStorage.setItem('link', link);
      } else if (temp[0] === 'name') {
        name = decodeURI(temp[1]);
      } else if (temp[0] === 'token') {
        imToken = temp[1];
      } else if (temp[0] === 'tgid') {
        tgid = Number(temp[1]);
      } else if (temp[0] === 'showMember') {
        showMember = Boolean(temp[1]);
      } else if (temp[0] === 'createChannel') {
        createChannel = Boolean(temp[1]);
      } else if (temp[0] === 'createGroup') {
        createGroup = Boolean(temp[1]);
      } else if (temp[0] === 'addContact') {
        addContact = Boolean(temp[1]);
      }
    }
    if (authState === 'authorizationStateReady') {
      // getUserFirstOrLastName();
      setTimeout(() => {
        const dom = document.getElementsByClassName('loginMask')[0];
        if (dom) {
          dom.classList.add('hide');
          const root = document.getElementById('root');
          root?.removeChild(dom);
        }
      }, 990);
      const userAgent = navigator.userAgent;
      const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // h5
      window.parent.postMessage({ type: 'stateReady' }, '*');
      // if (showMember) {
      //   window.userRights.showMember = showMember;
      // }
      // if (createChannel) {
      //   window.userRights.createChannel = createChannel;
      // }
      // if (createGroup) {
      //   window.userRights.createGroup = createGroup;
      // }
      // if (addContact) {
      //   window.userRights.addContact = addContact;
      // }
      // 苹果
      if (isiOS) {
        if (window.webkit && typeof window.webkit === 'object'
          && window.webkit.messageHandlers
          && typeof window.webkit.messageHandlers === 'object'
          && window.webkit.messageHandlers.TGWebview
          && typeof window.webkit.messageHandlers.TGWebview === 'object'
          && window.webkit.messageHandlers.TGWebview.postMessage
          && typeof window.webkit.messageHandlers.TGWebview.postMessage === 'function'
        ) {
          window.webkit?.messageHandlers?.TGWebview.postMessage({
            action: 'stateReady',
          });
        } else {
          window.parent.postMessage({ type: 'stateReady' }, '*');
        }
      }

      // 安卓
      if (window && window.Enterprise_SDK) {
        const params = {
          type: 'stateReady',
          payload: { params: {} },
        };
        sendMessageToNativeOrIframe(params);
      }
      // PC桌面端
      if (window &&  window.chrome && window.chrome.webview) {
        const params = {
          type: 'stateReady',
          payload: { params: {} },
        };
        sendMessageToNativeOrIframe(params);
      }
      // 安卓
      if (window.Android && window.Android.getReadyState) {
        window.Android.getReadyState('stateReady');
        const params = {
          type: 'stateReady',
          payload: { params: {} },
        };
        sendMessageToNativeOrIframe(params);
      }
      if (localStorage.getItem('user_auth') !== undefined && name) {
        // postChangeFirstName(name);
      }
    } else if (authState === 'authorizationStateUserMetaLogin' || authState === 'authorizationStateUserNameLogin') {
      let loginInfo = localStorage.getItem('loginInfo') as any;
      if (loginInfo) {
        loginInfo = JSON.parse(loginInfo);
        checkTokenIsExpiration().then((result: any) => {
          if (result) {
            const { imToken, tgid, isLogin } = loginInfo;
            // console.log('loginImToken=', imToken);
            if (imToken && tgid && isLogin) {
              getUserInfo().then((imToken: string) => {
                // console.log('getUserInfo=', imToken);
                window.getMessageFromApp('Login', {
                  UserID: tgid,
                  Token: imToken,
                });
                window.userRights = {
                  showMember,
                  createChannel,
                  createGroup,
                  addContact,
                };
              });
              // window.getMessageFromApp('Login', {
              //   UserID: tgid,
              //   Token: imToken,
              // });
              // window.userRights = {
              //   showMember,
              //   createChannel,
              //   createGroup,
              //   addContact,
              // };
            }
          } else {
            getActions().signOut();
            clearLocalStorage();
            window.location.reload();
          }
        });
      } else {
        toLogin();
      }
    }
  }, [authState]);

  useEffect(() => {
    // 设置语言
    const search = window.location.search.slice(1).split('&');
    const lang = localStorage.getItem('lang');
    if (lang) {
      setLanguage(lang as LangCode, () => {
        getActions().setSettingOption({ language: lang });
      });
    }
    // // eslint-disable-next-line
    // const lang = indexLang === -1 ? 'zh' : search[search.findIndex((item) => item.includes('lang'))].split('=')[1];
    // setLanguage(lang as LangCode, () => {
    //   getActions().setSettingOption({ language: lang });
    //   console.log('APP setLanguage');
    // });
    // 设置theme
    const indexTheme = search.findIndex((item) => item.includes('theme'));
    // eslint-disable-next-line
    const theme = indexTheme === -1 ? 'light' : search[search.findIndex((item) => item.includes('theme'))].split('=')[1];
    // const authorization = search[search.findIndex((item) => item.includes('authorization'))].split('=')[1];
    let authorization: string | number = (search.findIndex((item) => item.includes('authorization')));
    const global = getGlobal();
    getActions().setSettingOption({ theme });
    getActions().setSettingOption({ shouldUseSystemTheme: false });
    if (authorization > -1) {
      authorization = search[authorization].split('=')[1];
      getActions().setToken({ token: authorization });
    }
    switchTheme(theme as ThemeKey, global.settings.byKey.animationLevel === ANIMATION_LEVEL_MAX);
  }, []);
  useEffect(() => {
    const loginInfo = localStorage.getItem('loginInfo');
    if (urlInviteCode && loginInfo) {
      // setInviteCode(urlInviteCode);
      // 获取当前 URL 的查询参数
      const url = new URL(window.location.href);

      // 使用 URLSearchParams 解析查询字符串
      const params = new URLSearchParams(url.search);

      // 删除特定的查询参数，比如 'param1'
      params.delete('inviteCode');

      // 构造新的 URL
      url.search = params.toString();

      window.location.href = url.toString();
    }
  }, [urlInviteCode]);
  // useEffect(() => {
  //   // 访问接口获取token值
  //   if (authState === 'authorizationStateReady' && isClientStartup && currentUserId && forceInviteCode) {
  //     // debugger
  //     checkForceInviteCodeToGetToken();
  //   } else if (authState === 'authorizationStateReady' && isClientStartup && currentUserId && !forceInviteCode) {
  //     // debugger

  //     getToken(currentUserId, currentUsername, currentFirstName, currentLastName, phone)
  //   }
  // }, [authState, currentUserId, currentUsername, currentFirstName, currentLastName, phone, forceInviteCode, isClientStartup])

  let activeKey: number = -1;
  let page: UiLoaderPage | undefined;

  // if (isInactive) {
  //   activeKey = AppScreens.inactive;
  // } else if (isScreenLocked) {
  if (isScreenLocked) {
    page = 'lock';
    activeKey = AppScreens.lock;
  } else if (authState) {
    // 判断页面显示为未登录还是已登录
    switch (authState) {
      case 'authorizationStateUserNameLogin':
        page = 'authUserNameLogin';
        activeKey = AppScreens.auth;
        // postClientStartup();
        break;
      case 'authorizationStateSignUp':
        page = 'authUserNameSignUp';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateSetLanguage':
        page = 'authUserSetLanguage';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitPhoneNumber':
        page = 'authPhoneNumber';
        activeKey = AppScreens.auth;
        // 进入手机号登录页面调取接口，防止useEffect中的方法不二次触发
        // postClientStartup();
        break;
      case 'authorizationStateWaitCode':
        page = 'authCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitPassword':
        page = 'authPassword';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitRegistration':
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateWaitQrCode':
        page = 'authQrCode';
        activeKey = AppScreens.auth;
        break;
      case 'authorizationStateClosed':
      case 'authorizationStateClosing':
      case 'authorizationStateLoggingOut':
      case 'authorizationStateReady': // 登录成功的值
        page = 'main';
        activeKey = AppScreens.main;
        break;
      case 'authorizationStateVerifyLogin':
        page = 'authUserVerifyLogin';
        activeKey = AppScreens.auth;
        break;
    }
  } else if (hasStoredSession(true)) {
    page = 'main';
    activeKey = AppScreens.main;
    // getToken(currentUserId, currentUsername, currentFirstName, currentLastName, phone);
  } else if (hasPasscode) {
    activeKey = AppScreens.lock;
  } else {
    // page = isMobile ? 'authPhoneNumber' : 'authQrCode';
    page = 'authPhoneNumber';
    activeKey = AppScreens.auth;
  }

  if (activeKey !== AppScreens.lock
    && activeKey !== AppScreens.inactive
    && activeKey !== AppScreens.main
    && parseInitialLocationHash()?.tgWebAuthToken
    && !hasWebAuthTokenFailed) {
    page = 'main';
    activeKey = AppScreens.main;
  }

  const prevActiveKey = usePrevious(activeKey);

  // eslint-disable-next-line consistent-return
  function renderContent(isActive: boolean) {
    switch (activeKey) {
      case AppScreens.auth:
        return <Auth isActive={isActive} />;
      case AppScreens.main:
        return <Main />;
      case AppScreens.lock:
        return <LockScreen isLocked={isScreenLocked} />;
      case AppScreens.inactive:
        return <AppInactive />;
    }
  }

  return (
    <UiLoader key="Loader" page={page}>
      <Transition
        name="fade"
        activeKey={activeKey}
        shouldCleanup
        className={buildClassName(
          'full-height',
          (activeKey === AppScreens.auth || prevActiveKey === AppScreens.auth) && 'is-auth',
        )}
      >
        {renderContent}
      </Transition>
    </UiLoader>
  );
};

export default withGlobal(
  (global): StateProps => {
    const {
      currentUserId, canShowContacts, chats, token, forceInviteCode, isClientStartup, customerList,
    } = global;

    // 当前用户信息
    const currentUser = currentUserId ? selectUser(global, currentUserId) : undefined;
    // debugger

    if (!currentUser) {
      return {
        token,
        authState: global.authState,
        isScreenLocked: global.passcode?.isScreenLocked,
        hasPasscode: global.passcode?.hasPasscode,
        hasWebAuthTokenFailed: global.hasWebAuthTokenFailed || global.hasWebAuthTokenPasswordRequired,
        isLoggingOut: global.isLoggingOut,
        currentUserId,
        forceInviteCode,
        isClientStartup,
        customerList,
      };
    }

    const {
      firstName: currentFirstName,
      lastName: currentLastName,
      username: currentUsername,
      phoneNumber: phone,
    } = currentUser;

    return {
      token,
      forceInviteCode,
      canShowContacts,
      currentUserId,
      currentFirstName,
      currentLastName,
      currentUsername,
      phone,
      authState: global.authState,
      isScreenLocked: global.passcode?.isScreenLocked,
      hasPasscode: global.passcode?.hasPasscode,
      hasWebAuthTokenFailed: global.hasWebAuthTokenFailed || global.hasWebAuthTokenPasswordRequired,
      isLoggingOut: global.isLoggingOut,
      isClientStartup,
      customerList,
    };
  },
)(App);
