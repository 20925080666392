import type { ChangeEvent } from 'react';
import md5 from 'md5';

import type { FC } from '../../lib/teact/teact';
import React, {
  memo, useMemo, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';

import type { GlobalState } from '../../global/types';

import { IS_SAFARI } from '../../util/environment';
import { pick } from '../../util/iteratees';
import useLang from '../../hooks/useLang';

import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import InputText from '../ui/InputText';
import Loading from '../ui/Loading';
import AvatarEditable from '../ui/AvatarEditable';

import './AuthUserNameSignUp.scss';
// eslint-disable-next-line import/no-cycle
import {
  getParamsByUrl, Toast,
} from '../../util/toolUtils';
// eslint-disable-next-line import/no-cycle
import {
  HttpRquestUrl, userRegister,
} from '../../global/http/api';
import { ErrorMsgMap } from '../../global/http/useErrorMessage';
import type { AppInfoProps } from './AuthUserNameLogin';

type StateProps = Pick<GlobalState, (
  'authState' |
  'authIsLoading' |
  'authError' |
  'authRememberMe'
)> & {
  forceInviteCode?: string;
  regIpLimit: number;
  language: string;
};
export type loginCallback = (loginParams: { username: string; password: string }) => void;
const MIN_USERNAME_LENGTH = 5;
const MAX_USERNAME_LENGTH = 32;
const USERNAME_REGEX = /^[^\d]([a-zA-Z0-9_]+)$/;

const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 12;
// 密码规则：
//    字母：A-Z a-z 数字：0、1、2、3、4、5、6、7、8、9
//    特殊字符： !、@、#、$、%、^、&、*、(、)、-、_、+、=、{、}、[、]、|、\、:、;、"、'、<、>、,、.、?、/ 等
//    密码长度：6-12
const PASSWORD_REGEX = /^[A-Za-z0-9.,!@#$%^&*()_+=\-`~[\]/\\{}:"|<>?]{6,12}$/;
// 结尾不能加 g ，会引起bug
// https://www.65580.net/68253.html
// 如果使用全局匹配，那么在每一次查到后将lastIndex置零，或者不使用全局匹配，直接匹配即可。
// lastIndex从字面上来讲就是最后一个索引，实际上它的意思是正则表达式开始下一次查找的索引位置，第一次的时候总是为0的，
// 第一次查找完了的时候会把lastIndex的值设为匹配到得字符串的最后一个字符的索引位置加1，第二次查找的时候会从lastIndex这个位置开始，
// 后面的以此类推。如果没有找到，则会把lastIndex重置为0。要注意的是，lastIndex属性只有在有全局标志正则表达式中才有作用，
// 如果我们把上面代码中正则表达式的g标志去掉，那么三次弹出的就都是true了。

function isUsernameValid(username: string) {
  return username.length >= MIN_USERNAME_LENGTH
    && username.length <= MAX_USERNAME_LENGTH
    && USERNAME_REGEX.test(username);
}

const AuthUserNameSignUp: FC<StateProps> = ({
  authState,
  authIsLoading,
  authError,
  authRememberMe,
  forceInviteCode,
  regIpLimit,
  language,
}) => {
  const {
    goToAuthUserNameLogin,
    goToAuthSetLanguage,
    setAuthUserNameSignUp,
    setAuthRememberMe,
    clearAuthError,
    setToken,
    showNotification,
    setTemporaryInviteCode,
    setTemporaryRecommendCode,
    goToAuthVerifyLogin,
  } = getActions();

  const urlInviteCode = getParamsByUrl('inviteCode');
  const lang = useLang();
  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  const inputUserNameRef = useRef<HTMLInputElement>(null);
  const inviteCodeInputRef = useRef<HTMLInputElement>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);
  const inputRepeatPasswordRef = useRef<HTMLInputElement>(null);
  const inviteInputRef = useRef<HTMLInputElement>(null);
  const referralInputRef = useRef<HTMLInputElement>(null);

  const langPrefix = 'Username';
  const langPsswordfix = 'Password';
  const [userName, setUserName] = useState<string | ''>();
  const [password, setPassword] = useState<string | ''>();
  const [inviteCode, setInviteCode] = useState<string | null>();
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
  const [lastSelection, setLastSelection] = useState<[number, number] | undefined>();

  const [nowTemporaryInviteCode, setNowTemporaryInviteCode] = useState('');
  const [nowTemporaryReferralCode, setNowTemporaryReferralCode] = useState('');
  const [isRightInviteCode, setIsRightInviteCode] = useState(true);
  const [isRightReferralCode, setIsRightReferralCode] = useState(true);
  // const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    if (urlInviteCode && urlInviteCode !== '') {
      // userAutoRegister({ inviteCode: urlInviteCode }, handleErrorMsg)
      setInviteCode(urlInviteCode);
      setIsRightInviteCode(true);
    }
  }, [urlInviteCode]);
  // 下一步按钮的显示和隐藏判断
  const canSubmit = userName && password && confirmPassword && inviteCode;
  const [showYourName, setShowYourName] = useState(false);

  const [isButtonShown, setIsButtonShown] = useState(false);
  const [croppedFile, setCroppedFile] = useState<File | undefined>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [appInfo, setAppInfo] = useState<AppInfoProps>();

  useEffect(() => {
    const gAppInfo = localStorage.getItem('appInfo');
    if (gAppInfo) {
      const gAppInfoMap = JSON.parse(gAppInfo);
      const showAppInfo: AppInfoProps = {
        merchantLogoUrl: gAppInfoMap.merchantLogoUrl,
        merchantName: '',
        autofillInviteCode: gAppInfoMap.autofillInviteCode,
        isAutofillInviteCode: gAppInfoMap.isAutofillInviteCode,
      };
      switch (language) {
        case 'classic-zh-cn':
          showAppInfo.merchantName = gAppInfoMap.merchantName;
          break;
        case 'zh-hant-raw':
          showAppInfo.merchantName = gAppInfoMap.merchantNameZhTra;
          break;
        case 'en':
          showAppInfo.merchantName = gAppInfoMap.merchantNameEn;
          break;
        default:
          showAppInfo.merchantName = gAppInfoMap.merchantName;
      }
      setAppInfo(showAppInfo);
      // setInviteCode(showAppInfo.autofillInviteCode);
    } else {
      const defaultAppInfo: AppInfoProps = {
        merchantLogoUrl: '../../assets/logo.png',
        merchantName: lang('AppName'),
        autofillInviteCode: '',
        isAutofillInviteCode: 0,
      };
      setAppInfo(defaultAppInfo);
    }
  }, [language]);

  useLayoutEffect(() => {
    if (inputRef.current && lastSelection) {
      inputRef.current.setSelectionRange(...lastSelection);
    }
  }, [lastSelection]);

  // 是否是刚刚粘贴进去
  const isJustPastedRef = useRef(false);
  // 手动粘贴
  const handlePaste = useCallback(() => {
    isJustPastedRef.current = true;
    requestAnimationFrame(() => {
      isJustPastedRef.current = false;
    });
  }, []);

  const handleKeyDown = (event) => {
    // 阻止空格键的默认行为
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  // 用户名输入框出现变化
  const handleUserNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    const { value } = e.target;
    setUserName(value);

    const isValid = isUsernameValid(value);
    if (!isValid) return;
  }, [authError, clearAuthError]);

  const [usernameError] = useMemo(() => {
    // console.log(userName);

    if (!userName) {
      return [];
    }

    if (userName.length < MIN_USERNAME_LENGTH) {
      return [lang(`${langPrefix}InvalidShort`)];
    }
    if (userName.length > MAX_USERNAME_LENGTH) {
      return [lang(`${langPrefix}InvalidLong`)];
    }
    if (!USERNAME_REGEX.test(userName)) {
      return [lang(`${langPrefix}Invalid`)];
    }

    return [];
  }, [userName, lang, langPrefix]);

  // 注册密码输入框出现变化
  const handlePasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    const { value } = e.target;
    setPassword(value);
  }, [authError, clearAuthError]);

  const [passwordError] = useMemo(() => {
    if (!password) {
      return [];
    }
    if (password.length < MIN_PASSWORD_LENGTH) {
      return [lang(`${langPsswordfix}InvalidShort`)];
    }
    if (password.length > MAX_PASSWORD_LENGTH) {
      return [lang(`${langPsswordfix}InvalidLong`)];
    }
    if (!PASSWORD_REGEX.test(password)) {
      return [lang(`${langPsswordfix}Invalid`)];
    }
    return [];
  }, [password]);

  // 确认密码输入框出现变化
  const handleConfirmPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    const { value } = e.target;
    setConfirmPassword(value);
  }, [authError, clearAuthError]);

  const [confirmPasswordError] = useMemo(() => {
    if (!password || !confirmPassword) {
      return [];
    }
    if (password !== confirmPassword) {
      return [lang('TwoDifferentPasswords')];
    }

    return [];
  }, [password, confirmPassword]);

  // 邀请码输入框出现变化
  // const handleInviteCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   setNowTemporaryInviteCode(value);
  //   setIsRightInviteCode(true);
  // }, []);
  const handleInviteCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInviteCode(value);
    setIsRightInviteCode(true);
  }, []);

  // 推荐码输入框出现变化
  const handleReferralCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNowTemporaryReferralCode(value);
    setIsRightReferralCode(true);
  }, []);

  // const checkInviteCodeChange = useCallback(async() => {
  //   // debugger
  //   let isTure = await checkInviteCode(userName, nowTemporaryInviteCode)
  //   setIsSuccess(isTure)
  // }, [userName, nowTemporaryInviteCode])

  // const checkReferralCodeChange = useCallback(async() => {
  //   let isTure = await checkReferralCode(userName, nowTemporaryInviteCode)
  //   setIsSuccess(isTure)
  // }, [userName, nowTemporaryInviteCode])

  // const checkAllCode = useCallback(() => {
  //   // debugger
  //   if ((forceInviteCode === '1' || forceInviteCode === '0') && userName && nowTemporaryInviteCode) {
  //     checkInviteCodeChange()
  //   }
  //   if (forceInviteCode === '0' && userName && !nowTemporaryInviteCode) {
  //     checkInviteCodeChange()
  //   }
  //   if (userName && nowTemporaryReferralCode) {
  //     checkReferralCodeChange()
  //   }
  // }, [userName, nowTemporaryInviteCode, nowTemporaryReferralCode, checkInviteCodeChange, checkReferralCodeChange])

  // 检测邀请码是否正确
  // const checkInviteCode = useCallback((name, code) => {
  //   // let isSuccess: boolean = await postForceInviteCode('', name, code)
  //   // setIsRightInviteCode(isSuccess)
  //   // isSuccess && setTemporaryInviteCode({ code: code })
  //   // return isSuccess
  // }, [postForceInviteCode, setIsRightInviteCode])

  // 检测推荐码是否正确
  // const checkReferralCode = useCallback((name, code) => {
  //   //   let isSuccess: boolean = await postForceInviteCode('', name, code)
  //   //   setIsRightReferralCode(isSuccess)
  //   //   isSuccess && setTemporaryRecommendCode({ code: code })
  //   //   return isSuccess
  // }, [postForceInviteCode, setIsRightReferralCode])
  const handleKeepSessionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAuthRememberMe(e.target.checked);
  }, [setAuthRememberMe]);
  // 提交表单
  const submitForm = useCallback(() => {
    // debugger
    // 邀请码处理
    // if (forceInviteCode === '1' || forceInviteCode === '0') {
    //   const isSuccess = await checkInviteCode(userName, nowTemporaryInviteCode);
    //   if (isSuccess) {
    //     setAuthUserNameSignUp({ userName, password: md5(password), firstName, lastName })
    //   }
    // }

    // // 推荐码处理
    // else if (forceInviteCode === '2') {
    //   const isSuccess = await checkReferralCode(userName, nowTemporaryReferralCode)
    //   if (isSuccess) {
    // setAuthUserNameSignUp({ userName: userName, password: md5(password), firstName: firstName, lastName: lastName })
    //   }
    // }

    // else {
    setAuthUserNameSignUp({
      userName, password: md5(password), firstName, lastName,
    });
    // }
  }, [forceInviteCode, userName, password, firstName, lastName, nowTemporaryInviteCode, nowTemporaryReferralCode]);

  // 提交请求
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (authIsLoading) {
      return;
    }

    if (canSubmit) {
      submitForm();
    }
  }

  // }, [forceInviteCode, userName, password, firstName, lastName, isSuccess])

  // 继续下一步
  const changeShowYourName = () => {
    // debugger
    const isContinue = true;
    if (regIpLimit > 0) {
      // isContinue = await postCheckIPTimes(userName)
    }

    if (!isContinue) {

    } else {
      setShowYourName(!showYourName);
      if (showYourName) {
        clearAuthError();
      }
    }
  };
  const handleErrorMsg = useCallback((result: any) => {
    const key = ErrorMsgMap[result.code];
    if (key) {
      Toast(lang(key) as string, 2000);
    } else {
      Toast(result.msg, 2000);
    }
  }, []);
  const clearInputFocus = () => {
    inviteCodeInputRef.current?.blur();
    inputUserNameRef.current?.blur();
    inputPasswordRef.current?.blur();
    inputRepeatPasswordRef.current?.blur();
  };
  const handleSubmitForRegister = async () => {
    await clearInputFocus();
    if (canSubmit) {
      if (window && window.Enterprise_SDK && window.Enterprise_SDK.getPublicIpByThread) {
        window.Enterprise_SDK.getPublicIpByThread();
      }
      const params = {
        username: userName,
        password: md5(password),
        inviteCode,
      };
      userRegister(HttpRquestUrl.USER_REGISTER, params, handleErrorMsg, (loginParams: { username: string; password: string }) => {
        // userLogin(HttpRquestUrl.USER_LOGIN, loginParams, handleErrorMsg);
        if (window && window.Enterprise_SDK && window.Enterprise_SDK.registerAction) {
          window.Enterprise_SDK.registerAction();
        } else if (window.webkit && window.webkit.messageHandlers.Enterprise_SDK) {
          window.webkit.messageHandlers.Enterprise_SDK.postMessage({ type: "registerAction" });
        }
      });
    }
  };
  // firstName输入框变化
  const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setFirstName(target.value);
    setIsButtonShown(target.value.length > 0);
  }, []);

  // lastName输入框变化
  const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;

    setLastName(target.value);
  }, []);

  const isAuthReady = authState === 'authorizationStateSignUp';
  let logoBgCss = '';
  if (appInfo && appInfo.merchantLogoUrl) {
    logoBgCss = `background-image: url(${appInfo.merchantLogoUrl})`;
  }
  const isDisabledSignUp = Boolean(canSubmit) && !usernameError && !passwordError && !confirmPasswordError && isAuthReady;
  return (
    <div id="auth-user-info-form" className="custom-scroll auth-user-info-form-background">
      {/* 缩减返回箭头代码量 */}
      {/* eslint-disable-next-line */}
      <div className='goBackStyle' onClick={!showYourName ? (isAuthReady ? goToAuthUserNameLogin : undefined) : changeShowYourName} />
      <div className="set-language" onClick={() => goToAuthSetLanguage({ authState: 'authorizationStateSetLanguage' })}>{lang('lng_language_name')}</div>
      <div className="auth-form auth-form-custom">
        <div>
          {
            !showYourName
              ? (
                <>
                  {/* <div id="logo" className={'logo'} style={logoBgCss} /> */}
                  <h1 className="page-title">{lang('WelcomeSignup')}</h1>
                  {/* <h1> {appInfo && appInfo.merchantName}</h1> */}
                  <p className="note">{lang('SignupTextLogin')}</p>
                  <InputText
                    ref={inputUserNameRef}
                    id="sign-in-user-name"
                    label={lang('YourUserName')}
                    // label={'Your user name'}
                    value={userName}
                    error={usernameError}
                    inputMode="text"
                    onChange={handleUserNameChange}
                    onPaste={IS_SAFARI ? handlePaste : undefined}
                    onKeyDown={handleKeyDown}
                  />
                  <InputText
                    typeMode="password"
                    ref={inputPasswordRef}
                    id="sign-in-password"
                    label={lang('Password')}
                    value={password}
                    error={passwordError}
                    inputMode="text"
                    onChange={handlePasswordChange}
                    onPaste={IS_SAFARI ? handlePaste : undefined}
                    onKeyDown={handleKeyDown}
                  />
                  <InputText
                    typeMode="password"
                    ref={inputRepeatPasswordRef}
                    id="sign-up-confirm-password"
                    label={lang('ConfirmPassword')}
                    value={confirmPassword}
                    error={confirmPasswordError}
                    inputMode="text"
                    onChange={handleConfirmPasswordChange}
                    onPaste={IS_SAFARI ? handlePaste : undefined}
                    onKeyDown={handleKeyDown}
                  />
                  {
                    appInfo && appInfo.isAutofillInviteCode === 0
                      ? (
                        <InputText
                          ref={inviteCodeInputRef}
                          id="sign-in-referral-code"
                          // label={'Referral code (Required filling）'}
                          label={lang('InviteCodeRequired')}
                          value={inviteCode || ''}
                          inputMode="text"
                          onChange={handleInviteCodeChange}
                          onPaste={IS_SAFARI ? handlePaste : undefined}
                          onKeyDown={handleKeyDown}
                        />
                      )
                      : null
                  }

                  {
                    (forceInviteCode === '0' || forceInviteCode === '1')
                      ? (
                        // Invite code 邀请码
                        <InputText
                          ref={inviteInputRef}
                          id="sign-in-invite-code"
                          label={forceInviteCode === '0' ? lang('InviteCodeOptional') : lang('InviteCodeRequired')}
                          value={nowTemporaryInviteCode}
                          // error={authError && lang(authError)}
                          inputMode="text"
                          onChange={handleInviteCodeChange}
                          onPaste={IS_SAFARI ? handlePaste : undefined}
                          onKeyDown={handleKeyDown}
                        />
                      )
                      : (forceInviteCode === '2'
                        ? (
                          // Referral Code 推荐码
                          <InputText
                            ref={referralInputRef}
                            id="sign-in-referral-code"
                            // label={'Referral code (Required filling）'}
                            label={lang('ReferralCodeRequired')}
                            value={nowTemporaryReferralCode}
                            inputMode="text"
                            onChange={handleReferralCodeChange}
                            onPaste={IS_SAFARI ? handlePaste : undefined}
                            onKeyDown={handleKeyDown}
                          />
                        )
                        : undefined
                      )
                  }

                  {
                    !isRightInviteCode
                      ? (
                        <div className="divStyleCss">
                          {/* 待翻译 */}
                          {/* Invite code absence! */}
                          {lang('InviteCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }

                  {
                    !isRightReferralCode
                      ? (
                        <div className="divStyleCss">
                          {/* 待翻译 */}
                          {/* Referral code absence! */}
                          {lang('ReferralCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }
                  {/* <Button isText onClick={goToAuthSignUp}>New registration</Button> */}
                  {/* <Checkbox
                    id="sign-up-keep-session"
                    // label="Keep me signed in"
                    label={lang('KeepMeSignedIn')}
                    checked={Boolean(authRememberMe)}
                    onChange={handleKeepSessionChange}
                  /> */}
                  <Button
                    ripple
                    isLoading={authIsLoading}
                    onClick={handleSubmitForRegister}
                    disabled={!isDisabledSignUp}
                    className="sign-up"
                  >
                    {lang('Login.Header.SignUp')}
                  </Button>
                  {/* {
                    // TODO 暂时放开注册按钮限制
                    // (((forceInviteCode === '1') && nowTemporaryInviteCode) || ((forceInviteCode === '2') && nowTemporaryReferralCode) || (forceInviteCode === '0' || forceInviteCode === '3')) && canSubmit && !usernameError && !passwordError && !confirmPasswordError && (
                    // eslint-disable-next-line
                    ( canSubmit && !usernameError && !passwordError && !confirmPasswordError && (
                      isAuthReady ? (
                        // <Button type="submit" ripple  isLoading={authIsLoading}>{lang('Login.Next')}</Button>
                        // eslint-disable-next-line
                        <Button ripple isLoading={authIsLoading} onClick={handleSubmitForRegister}>{lang('Login.Header.SignUp')}</Button>
                      ) : (
                        <Loading />
                      )
                    ))
                  } */}

                  {/* {suggestedLanguage && suggestedLanguage !== language && continueText && (
            <Button isText isLoading={isLoading} onClick={handleLangChange}>{continueText}</Button>
          )} */}
                </>
              )
              : (
                <>
                  <AvatarEditable onChange={setCroppedFile} />
                  <h2>{lang('YourName')}</h2>
                  <p className="note">{lang('Login.Register.Desc')}</p>
                  <InputText
                    id="registration-first-name"
                    label={lang('Login.Register.FirstName.Placeholder')}
                    onChange={handleFirstNameChange}
                    value={firstName}
                    typeMode="text"
                    inputMode="text"
                    // error={authError && lang(authError)}
                    autoComplete="given-name"
                  />
                  <InputText
                    id="registration-last-name"
                    label={lang('Login.Register.LastName.Placeholder')}
                    onChange={handleLastNameChange}
                    typeMode="text"
                    inputMode="text"
                    value={lastName}
                    autoComplete="family-name"
                  />
                  <p>{lang('BySigningUpStr')}<a href="#">{lang('TermsOfService')}</a></p>
                  {
                    authError && <p className="pStyleCss">{lang(authError)}</p>
                  }

                  {
                    !isRightInviteCode
                      ? (
                        <div className="divStyleCss">
                          {/* 待翻译 */}
                          {/* Invite code absence! */}
                          {lang('InviteCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }

                  {
                    !isRightReferralCode
                      ? (
                        <div className="divStyleCss">
                          {/* 待翻译 */}
                          {/* Referral code absence! */}
                          {lang('ReferralCodeNonExistent')}
                        </div>
                      )
                      : ''
                  }
                  {isButtonShown && (
                    // eslint-disable-next-line
                    <Button type="submit" disabled={!!authError} ripple isLoading={authIsLoading}>{lang('Continue')}</Button>
                  )}
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default memo(withGlobal(
  (global): StateProps => {
    const {
      forceInviteCode,
      regIpLimit,
    } = global;
    const language = global.settings.byKey.language;
    return {
      ...pick(global, [
        'authState',
        'authIsLoading',
        'authError',
        'authRememberMe',
      ]),
      forceInviteCode,
      regIpLimit,
      language,
    };
  },
)(AuthUserNameSignUp));
