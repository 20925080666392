import type { FC } from '../../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from '../../../lib/teact/teact';
import { getActions, withGlobal } from '../../../global';

import type { ISettings, LangCode } from '../../../types';
import type { ApiLanguage } from '../../../api/types';

import { setLanguage } from '../../../util/langProvider';

import RadioGroup from '../../ui/RadioGroup';
import Button from '../../ui/Button';
import Loading from '../../ui/Loading';
import useFlag from '../../../hooks/useFlag';
import useHistoryBack from '../../../hooks/useHistoryBack';
import './Settings.scss';
import useLang from '../../../hooks/useLang';
type OwnProps = {
  isActive?: boolean;
  onReset: () => void;
};

type StateProps = Pick<ISettings, 'languages' | 'language'>;

// 语言设置
const SettingsLanguage: FC<OwnProps & StateProps> = ({
  isActive,
  onReset,
  languages,
  language,
}) => {
  const {
    loadLanguages,
    setSettingOption,
  } = getActions();
  const lang = useLang();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(language);
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  // TODO Throttle
  useEffect(() => {
    loadLanguages();
  }, [loadLanguages]);

  const handleChange = useCallback((langCode: string) => {
    setSelectedLanguage(langCode);
    markIsLoading();

    void setLanguage(langCode as LangCode, () => {
      unmarkIsLoading();
      setSettingOption({ language: langCode });
    });
  }, [markIsLoading, unmarkIsLoading, setSettingOption]);

  const options = useMemo(() => {
    return languages ? buildOptions(languages) : undefined;
  }, [languages]);

  useHistoryBack({
    isActive,
    onBack: onReset,
  });
  const renderLanguageHeader = ()=> {
    return <div className='language-header'>
        <Button
          round
          size="smaller"
          color="translucent"
          onClick={onReset}
          ariaLabel="Return to Chat List"
        >
          <div className='goBackStyle'></div>
        </Button>
        <div className='title'>{lang('lng_set_language')}</div>
    </div>;
  } 
  const renderLanguageContent = ()=> {
    return <div className="settings-content settings-item settings-language custom-scroll settings-item--first">
    {options ? (
      <RadioGroup
        name="keyboard-send-settings"
        options={options}
        selected={selectedLanguage}
        loadingOption={isLoading ? selectedLanguage : undefined}
        onChange={handleChange}
      />
    ) : (
      <Loading />
    )}
  </div>
  }
  return (
    <div className='settings-language-container'>
      {
        renderLanguageHeader()
      }
      {
        renderLanguageContent()
      }
    </div>
  );
};

function buildOptions(languages: ApiLanguage[]) {
  // debugger
  const currentLangCode = localStorage.getItem('lang');
  // let languageList: ApiLanguage[];
  // languages.forEach((item) => {
  //   if (item.langCode !== 'fa-raw') {
  //     languageList.push(item);
  //   }
  // })

  return languages.map(({ langCode, nativeName, name }) => ({
    value: langCode,
    label: nativeName,
    subLabel: name,
  })).sort((a) => {
    return currentLangCode && (a.value === currentLangCode) ? -1 : 0;
  });
}

export default memo(withGlobal<OwnProps>(
  (global): StateProps => {
    return {
      languages: global.settings.byKey.languages,
      language: global.settings.byKey.language,
    };
  },
)(SettingsLanguage));
