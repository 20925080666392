import Api from '../tl/api';
import type TelegramClient from './TelegramClient';
import utils from '../Utils';
import { sleep } from '../Helpers';
import { computeCheck as computePasswordSrpCheck } from '../Password';
// eslint-disable-next-line
import bigInt from 'big-integer';

export interface UserAuthParams {
    userNameLogin: () => Promise<[string, string]>;
    userNameSignUp: () => Promise<[string, string, string, string]>;
    userSetLanguage: () => Promise<void>;
    phoneNumber: string | (() => Promise<string>);
    webAuthTokenFailed: () => void;
    phoneCode: (isCodeViaApp?: boolean) => Promise<string>;
    password: (hint?: string, noReset?: boolean) => Promise<string>;
    firstAndLastNames: () => Promise<[string, string?]>;
    qrCode: (qrCode: { token: Buffer; expires: number }) => Promise<void>;
    onError: (err: Error) => void;
    forceSMS?: boolean;
    // initialMethod?: 'phoneNumber' | 'qrCode';
    initialMethod?: 'phoneNumber' | 'userNameLogin';
    shouldThrowIfUnauthorized?: boolean;
    webAuthToken?: string;
    metaLogin:boolean;
    metaAuthLogin: () => Promise<[number, number, string]>;
}

export interface BotAuthParams {
    botAuthToken: string;
}

interface ApiCredentials {
    apiId: number;
    apiHash: string;
}

// const DEFAULT_INITIAL_METHOD = 'phoneNumber';
const DEFAULT_INITIAL_METHOD = 'userNameLogin';
const QR_CODE_TIMEOUT = 30000;

// 身份验证流--官方更新代码
export async function authFlow(
    client: TelegramClient,
    apiCredentials: ApiCredentials,
    authParams: UserAuthParams | BotAuthParams,
) {
    let me: Api.TypeUser;
    if ('botAuthToken' in authParams) {
        me = await signInBot(client, apiCredentials, authParams);
        // 下面两个判断均为官方更新方法，需要验证是否有问题
    } else if ('webAuthToken' in authParams && authParams.webAuthToken) {
        me = await signInUserWithWebToken(client, apiCredentials, authParams);
    } else {
        me = await signInUserWithPreferredMethod(client, apiCredentials, authParams);
    }
    // eslint-disable-next-line
    client._log.info('Signed in successfully as', utils.getDisplayName(me));
}

// 首选用户的登录的方式
export function signInUserWithPreferredMethod(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // 获取初始化的值
    const { initialMethod = DEFAULT_INITIAL_METHOD } = authParams;
    // me = await signInUser(client, apiCredentials, authParams);
    if (initialMethod === 'phoneNumber') {
    // debugger
        return signInUser(client, apiCredentials, authParams);
    } else if (authParams.metaLogin) {
        return signInUserMetaLogin(client, apiCredentials, authParams);
    } else {
    // console.log(client);
    // console.log(apiCredentials);
    // console.log(authParams);
    // debugger
    // return signInUserWithQrCode(client, apiCredentials, authParams);
        return signInUserNameLogin(client, apiCredentials, authParams);
    }
}

// 检查授权
export async function checkAuthorization(client: TelegramClient, shouldThrow = false) {
    try {
        await client.invoke(new Api.updates.GetState());
        return true;
    } catch (e: any) {
        if (e.message === 'Disconnect' || shouldThrow) throw e;
        return false;
    }
}

async function signInUserWithWebToken(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    try {
        const { apiId, apiHash } = apiCredentials;
        const sendResult = await client.invoke(new Api.auth.ImportWebTokenAuthorization({
            webAuthToken: authParams.webAuthToken,
            apiId,
            apiHash,
        }));

        if (sendResult instanceof Api.auth.Authorization) {
            return sendResult.user;
        } else {
            throw new Error('SIGN_UP_REQUIRED');
        }
    } catch (err: any) {
        if (err.message === 'SESSION_PASSWORD_NEEDED') {
            return signInWithPassword(client, apiCredentials, authParams, true);
        } else {
            client._log.error(`Failed to login with web token: ${err}`);
            authParams.webAuthTokenFailed();
            return signInUserWithPreferredMethod(client, apiCredentials, {
                ...authParams,
                webAuthToken: undefined,
            });
        }
    }
}
// 用户手机号登录
async function signInUser(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // debugger
    let phoneNumber;
    let phoneCodeHash;
    let isCodeViaApp = false;

    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
            // debugger
            if (typeof authParams.phoneNumber === 'function') {
                try {
                    await authParams.metaAuthLogin();
                    phoneNumber = await authParams.phoneNumber();
                } catch (err: any) {
                    // debugger
                    // 判断 authController.reject(new Error()); 中的值是否是二维码登录抛出，如果是就进入二维码登录方法，如果不是则抛出错误
                    if (err.message === 'RESTART_AUTH_WITH_QR') {
                        return signInUserWithQrCode(client, apiCredentials, authParams);
                    }
                    if (err.message === 'RESTART_USER_NAME_LOGIN') {
                        return signInUserNameLogin(client, apiCredentials, authParams);
                    }
                    // 抛出非RESTART_AUTH_WITH_QR错误提示的内容，进入到外层的 catch 中
                    throw err;
                }
            } else {
                phoneNumber = authParams.phoneNumber;
            }
            const sendCodeResult = await sendCode(client, apiCredentials, phoneNumber, authParams.forceSMS);
            phoneCodeHash = sendCodeResult.phoneCodeHash;
            isCodeViaApp = sendCodeResult.isCodeViaApp;

            if (typeof phoneCodeHash !== 'string') {
                throw new Error('Failed to retrieve phone code hash');
            }

            break;
        } catch (err: any) {
            // 上面 try 中 的throw err会进入到这里进行判断
            if (typeof authParams.phoneNumber !== 'function') {
                throw err;
            }
            // 这里就会执行错误，在控制台出现错误语句
            authParams.onError(err);
        }
    }

    let phoneCode;
    let isRegistrationRequired = false; // 是否是注册的，默认为false
    let termsOfService;

    // eslint-disable-next-line no-constant-condition
    while (1) {
        try {
            // debugger
            try {
                phoneCode = await authParams.phoneCode(isCodeViaApp);
            } catch (err: any) {
                // This is the support for changing phone number from the phone code screen.
                if (err.message === 'RESTART_AUTH') {
                    return signInUser(client, apiCredentials, authParams);
                }
            }

            if (!phoneCode) {
                throw new Error('Code is empty');
            }

            // May raise PhoneCodeEmptyError, PhoneCodeExpiredError,
            // PhoneCodeHashEmptyError or PhoneCodeInvalidError.
            const result = await client.invoke(new Api.auth.SignIn({
                phoneNumber,
                phoneCodeHash,
                phoneCode,
            }));

            if (result instanceof Api.auth.AuthorizationSignUpRequired) {
                isRegistrationRequired = true;
                termsOfService = result.termsOfService;
                break;
            }

            return result.user;
        } catch (err: any) {
            if (err.message === 'SESSION_PASSWORD_NEEDED') {
                return signInWithPassword(client, apiCredentials, authParams);
            } else {
                authParams.onError(err);
            }
        }
    }

    // 如果是注册的，进入下面的逻辑
    if (isRegistrationRequired) {
        // eslint-disable-next-line no-constant-condition
        while (1) {
            try {
                const [firstName, lastName] = await authParams.firstAndLastNames();
                if (!firstName) {
                    throw new Error('First name is required');
                }

                // 调用服务端注册接口
                const { user } = await client.invoke(new Api.auth.SignUp({
                    phoneNumber,
                    phoneCodeHash,
                    firstName,
                    lastName,
                })) as Api.auth.Authorization;

                if (termsOfService) {
                    // This is a violation of Telegram rules: the user should be presented with and accept TOS.
                    await client.invoke(new Api.help.AcceptTermsOfService({ id: termsOfService.id }));
                }

                return user;
            } catch (err: any) {
                authParams.onError(err);
            }
        }
    }
    authParams.onError(new Error('Auth failed'));
    return signInUser(client, apiCredentials, authParams);
}

// 二维码登录
async function signInUserWithQrCode(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // debugger
    // 是否完成扫描
    let isScanningComplete = false;

    const inputPromise = (async () => {
        // eslint-disable-next-line no-constant-condition
        while (1) {
            if (isScanningComplete) {
                break;
            }

            const result = await client.invoke(new Api.auth.ExportLoginToken({
                apiId: Number(process.env.TELEGRAM_T_API_ID),
                apiHash: process.env.TELEGRAM_T_API_HASH,
                exceptIds: [],
            }));
            if (!(result instanceof Api.auth.LoginToken)) {
                throw new Error('Unexpected');
            }

            const { token, expires } = result;

            await Promise.race([
                authParams.qrCode({ token, expires }),
                sleep(QR_CODE_TIMEOUT),
            ]);
        }
    })();

    const updatePromise = new Promise<void>((resolve) => {
        client.addEventHandler((update: Api.TypeUpdate) => {
            if (update instanceof Api.UpdateLoginToken) {
                resolve();
            }
        }, { build: (update: object) => update });
    });
    // debugger
    try {
        // Either we receive an update that QR is successfully scanned,
        // or we receive a rejection caused by user going back to the regular auth form
        // 要么我们收到QR扫描成功的更新，
        // 或者我们收到由于用户返回到常规身份验证表单而导致的拒绝
        await Promise.race([updatePromise, inputPromise]);
    } catch (err: any) {
        if (err.message === 'RESTART_AUTH') {
            return await signInUser(client, apiCredentials, authParams);
        }

        throw err;
    } finally {
        isScanningComplete = true;
    }

    try {
        const result2 = await client.invoke(new Api.auth.ExportLoginToken({
            apiId: Number(process.env.TELEGRAM_T_API_ID),
            apiHash: process.env.TELEGRAM_T_API_HASH,
            exceptIds: [],
        }));

        if (result2 instanceof Api.auth.LoginTokenSuccess && result2.authorization instanceof Api.auth.Authorization) {
            return result2.authorization.user;
        } else if (result2 instanceof Api.auth.LoginTokenMigrateTo) {
            await client._switchDC(result2.dcId);
            const migratedResult = await client.invoke(new Api.auth.ImportLoginToken({
                token: result2.token,
            }));

            if (migratedResult instanceof Api.auth.LoginTokenSuccess
                && migratedResult.authorization instanceof Api.auth.Authorization) {
                return migratedResult.authorization.user;
            }
        }
    } catch (err: any) {
        if (err.message === 'SESSION_PASSWORD_NEEDED') {
            return signInWithPassword(client, apiCredentials, authParams);
        }

        throw err;
    }

    // This is a workaround for TypeScript (never actually reached)
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw undefined;
}
async function signInSetLanguage(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // debugger
    let userName;
    let password;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
        // debugger
            try {
                await authParams.userSetLanguage();
            } catch (err: any) {
                console.log(err)
                // debugger
                // 进入到手机号登录
                if (err.message === 'RESTART_AUTH') {
                    return await signInUser(client, apiCredentials, authParams);
                }

                // 进入到用户注册
                if (err.message === 'RESTART_SIGN_UP') {
                    return await signUpWithoutPhone(client, apiCredentials, authParams);
                }

                if (err.message === 'RESTART_USER_NAME_LOGIN') {
                    return signInUserNameLogin(client, apiCredentials, authParams);
                }
                if (err.message === 'RESTART_USER_META_LOGIN') {
                    signInUserMetaLogin(client, apiCredentials, authParams);
                }
                if(err.message === 'RESTART_SET_LANGUAGE'){
                    return await signInSetLanguage(client, apiCredentials, authParams);
                }
                // 抛出非RESTART_AUTH_WITH_QR错误提示的内容，进入到外层的 catch 中
                throw err;
            }
            // debugger
            if (!userName) {
                throw new Error('userName is empty');
            }
            if (!password) {
                throw new Error('password is empty');
            }
            // console.log(Api.auth);
            // console.log(Api.auth.SignInWithoutPhone);

            const { user } = await client.invoke(new Api.auth.SignInWithoutPhone({
                userName,
                password,
            })) as Api.auth.Authorization;
            return user;
        } catch (err: any) {
        // 上面 try 中 的throw err会进入到这里进行判断
        // debugger
            if (typeof authParams.userNameLogin !== 'function') {
                throw err;
            }
            // 这里就会执行错误，在控制台出现错误语句
            authParams.onError(err);
        }
    }
}
// 用户名密码登录
async function signInUserNameLogin(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // debugger
    let userName;
    let password;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
        // debugger
            try {
                const [name, oldPassword] = await authParams.userNameLogin();
                userName = name;
                password = oldPassword;
                //   console.log(name);
                //   console.log(userName);
                //   console.log(oldPassword);
                //   console.log(password);
            } catch (err: any) {
                // debugger
                // 进入到手机号登录
                if (err.message === 'RESTART_AUTH') {
                    return await signInUser(client, apiCredentials, authParams);
                }

                // 进入到用户注册
                if (err.message === 'RESTART_SIGN_UP') {
                    return await signUpWithoutPhone(client, apiCredentials, authParams);
                }

                if (err.message === 'RESTART_USER_NAME_LOGIN') {
                    return signInUserNameLogin(client, apiCredentials, authParams);
                }
                if (err.message === 'RESTART_USER_META_LOGIN') {
                    signInUserMetaLogin(client, apiCredentials, authParams);
                }
                if(err.message === 'RESTART_SET_LANGUAGE'){
                    signInSetLanguage(client, apiCredentials, authParams);
                }
                // 抛出非RESTART_AUTH_WITH_QR错误提示的内容，进入到外层的 catch 中
                throw err;
            }
            // debugger
            if (!userName) {
                throw new Error('userName is empty');
            }
            if (!password) {
                throw new Error('password is empty');
            }
            // console.log(Api.auth);
            // console.log(Api.auth.SignInWithoutPhone);

            const { user } = await client.invoke(new Api.auth.SignInWithoutPhone({
                userName,
                password,
            })) as Api.auth.Authorization;
            return user;
        } catch (err: any) {
        // 上面 try 中 的throw err会进入到这里进行判断
        // debugger
            if (typeof authParams.userNameLogin !== 'function') {
                throw err;
            }
            // 这里就会执行错误，在控制台出现错误语句
            authParams.onError(err);
        }
    }
}

// 用户名注册
async function signUpWithoutPhone(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams,
): Promise<Api.TypeUser> {
    // debugger

    let userName;
    let password;
    let firstName;
    let lastName;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        try {
        // debugger

            try {
                const [name, oldPassword, firstN, lastN] = await authParams.userNameSignUp();
                userName = name;
                password = oldPassword;
                firstName = firstN;
                lastName = lastN;
                // eslint-disable-next-line no-console
                console.log({
                    userName,
                    password,
                    firstName,
                    lastName,
                });

                if (!firstName) {
                    throw new Error('First name is required');
                }
            } catch (err: any) {
            // debugger
            // 进入到手机号登录
                console.log(err, err);
                if (err.message === 'RESTART_AUTH') {
                    return await signInUser(client, apiCredentials, authParams);
                }

                // 进入到用户注册
                if (err.message === 'RESTART_SIGN_UP') {
                    return await signUpWithoutPhone(client, apiCredentials, authParams);
                }

                if (err.message === 'RESTART_USER_NAME_LOGIN') {
                    return signInUserNameLogin(client, apiCredentials, authParams);
                }
                if (err.message === 'RESTART_USER_META_LOGIN') {
                    signInUserMetaLogin(client, apiCredentials, authParams);
                }
                // 抛出非RESTART_AUTH_WITH_QR错误提示的内容，进入到外层的 catch 中
                throw err;
            }
            // debugger
            const { user } = await client.invoke(new Api.auth.SignUpWithoutPhone({
                userName,
                password,
                firstName,
                lastName,
            })) as Api.auth.Authorization;
            // console.log(user);

            return user;
        } catch (err: any) {
            // debugger
            // 上面 try 中 的throw err会进入到这里进行判断
            if (typeof authParams.phoneNumber !== 'function') {
                throw err;
            }
            // 这里就会执行错误，在控制台出现错误语句
            authParams.onError(err);
        }
    }
}

// 發送验证码
async function sendCode(
  client: TelegramClient, apiCredentials: ApiCredentials, phoneNumber: string, forceSMS = false,
): Promise<{
  phoneCodeHash: string;
  isCodeViaApp: boolean;
}> {
  try {
    const { apiId, apiHash } = apiCredentials;
    const sendResult = await client.invoke(new Api.auth.SendCode({
      phoneNumber,
      apiId,
      apiHash,
      settings: new Api.CodeSettings(),
    }));

    // If we already sent a SMS, do not resend the phoneCode (hash may be empty)
    if (!forceSMS || (sendResult.type instanceof Api.auth.SentCodeTypeSms)) {
      return {
        phoneCodeHash: sendResult.phoneCodeHash,
        isCodeViaApp: sendResult.type instanceof Api.auth.SentCodeTypeApp,
      };
    }

    const resendResult = await client.invoke(new Api.auth.ResendCode({
      phoneNumber,
      phoneCodeHash: sendResult.phoneCodeHash,
    }));

    return {
      phoneCodeHash: resendResult.phoneCodeHash,
      isCodeViaApp: resendResult.type instanceof Api.auth.SentCodeTypeApp,
    };
  } catch (err: any) {
    if (err.message === 'AUTH_RESTART') {
      return sendCode(client, apiCredentials, phoneNumber, forceSMS);
    } else {
      throw err;
    }
  }
}

// 密码登录
async function signInWithPassword(
    client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams, noReset = false,
): Promise<Api.TypeUser> {
    // eslint-disable-next-line no-constant-condition
    while (1) {
        try {
            const passwordSrpResult = await client.invoke(new Api.account.GetPassword());
            const password = await authParams.password(passwordSrpResult.hint, noReset);
            if (!password) {
                throw new Error('Password is empty');
            }

            const passwordSrpCheck = await computePasswordSrpCheck(passwordSrpResult, password);
            const { user } = await client.invoke(new Api.auth.CheckPassword({
                password: passwordSrpCheck,
            })) as Api.auth.Authorization;

            return user;
        } catch (err: any) {
            authParams.onError(err);
        }
    }

    // eslint-disable-next-line no-unreachable
    return undefined!; // Never reached (TypeScript fix)
}

// 机器人登录
async function signInBot(client: TelegramClient, apiCredentials: ApiCredentials, authParams: BotAuthParams) {
    const { apiId, apiHash } = apiCredentials;
    const { botAuthToken } = authParams;

    const { user } = await client.invoke(new Api.auth.ImportBotAuthorization({
        apiId,
        apiHash,
        botAuthToken,
    })) as Api.auth.Authorization;

    return user;
}

async function signInUserMetaLogin(client: TelegramClient, apiCredentials: ApiCredentials, authParams: UserAuthParams) {
    let metaID;
    let metaPartnerId;
    let bytes;
    while (true) {
        try {
        // debugger
            try {
                const user = await authParams.metaAuthLogin();
                [metaID, metaPartnerId, bytes] = user;
            } catch (err: any) {
                // debugger
                // 进入到手机号登录
                if (err.message === 'RESTART_AUTH') {
                    return await signInUser(client, apiCredentials, authParams);
                }

                // 进入到用户注册
                if (err.message === 'RESTART_SIGN_UP') {
                    return await signUpWithoutPhone(client, apiCredentials, authParams);
                }

                if (err.message === 'RESTART_USER_NAME_LOGIN') {
                    return signInUserNameLogin(client, apiCredentials, authParams);
                }
                if (err.message === 'RESTART_USER_META_LOGIN') {
                    signInUserMetaLogin(client, apiCredentials, authParams);
                }
                // 抛出非RESTART_AUTH_WITH_QR错误提示的内容，进入到外层的 catch 中
                throw err;
            }
            // debugger
            if (!metaID) {
                throw new Error('MetaID is empty');
            }
            if (!metaPartnerId) {
                throw new Error('partnerId is empty');
            }
            if (!bytes) {
                throw new Error('metaBytes is empty');
            }
            // console.log(Api.auth);
            // console.log(Api.auth.SignInWithoutPhone);
            // debugger
            const { user } = await client.invoke(new Api.auth.ImportAuthorizationNew({
                id: bigInt(metaID), partnerId: bigInt(metaPartnerId), token: bytes,
            })) as Api.auth.Authorization;
            // debugger
            return user;

            // const { user } = await client.invoke(new Api.auth.SignInWithoutPhone({
            //     userName,
            //     password,
            // })) as Api.auth.Authorization;
            // return user;
        } catch (err: any) {
        // 上面 try 中 的throw err会进入到这里进行判断
        // debugger
            if (typeof authParams.userNameLogin !== 'function') {
                throw err;
            }
            // 这里就会执行错误，在控制台出现错误语句
            authParams.onError(err);
        }
    }
}
