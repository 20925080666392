import type { FC } from '../../lib/teact/teact';
import React, { memo } from '../../lib/teact/teact';
import { Bundles } from '../../util/moduleLoader';

import useModuleLoader from '../../hooks/useModuleLoader';

const MainAsync: FC = () => {
  const main = Bundles.Main;
  const key: 'Main' | 'LockScreen' = 'Main';
  const Main = useModuleLoader(main, key);

  return Main ? <Main /> : undefined;
};

export default memo(MainAsync);
