// 登录通知客户端
export function appLogin(userID,userFristName,userLastName,browserID) {
  // debugger
  let param = {userID,userFristName,userLastName,browserID}
  let jsonParam = JSON.stringify(param)
  // return app.invoke('login', '{"userID": '+userID+', "userFristName": '+userFristName+', "userLastName": '+userLastName+', "broswerID": '+broswerID+'}');
  // eslint-disable-next-line
  return app.invoke('login', jsonParam);
}

// 登出通知客户端
export function appLogout(userID, browserID) {
  console.log('appLogout', userID);
  let param = {userID, browserID}
  // debugger
  let jsonParam = JSON.stringify(param)
  // eslint-disable-next-line
  return app.invoke('logout', jsonParam);
  // app.('logout', '{"userID": "123, "broswerID": "123"}');
}


// 未读数通知客户端
export function appNotifyMsgCount(userID, msgCount, browserID) {
  let param = {userID, msgCount, browserID}
  // debugger
  let jsonParam = JSON.stringify(param)
  // eslint-disable-next-line
  return app.invoke('notifyMsgCount', jsonParam);
  // app.invoke('notifyMsgCount',  '{"userID": "123",  "msgCount":"100", "broswerID": "123"}');
}
