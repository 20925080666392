import type { GlobalState } from '../types';
import { NewChatMembersProgress, RightColumnContent, ChangeGroupUserPermissionsType } from '../../types';

import { getSystemTheme, IS_SINGLE_COLUMN_LAYOUT } from '../../util/environment';
import { selectCurrentMessageList, selectIsPollResultsOpen } from './messages';
import { selectCurrentTextSearch } from './localSearch';
import { selectCurrentStickerSearch, selectCurrentGifSearch } from './symbols';
import { selectIsStatisticsShown, selectIsMessageStatisticsShown } from './statistics';
import { selectCurrentManagement } from './management';

export function selectIsMediaViewerOpen(global: GlobalState) {
  const { mediaViewer } = global;
  return Boolean(mediaViewer.mediaId || mediaViewer.avatarOwnerId);
}

// 筛选出右侧内容模块显示的关键词
export function selectRightColumnContentKey(global: GlobalState) {
  // debugger
  if (selectIsPollResultsOpen(global)) {
    return RightColumnContent.PollResults
  } else if (!IS_SINGLE_COLUMN_LAYOUT && selectCurrentTextSearch(global)) {
    return RightColumnContent.Search
  } else if (selectCurrentManagement(global)) {
    // debugger
    return RightColumnContent.Management
  } else if (selectIsMessageStatisticsShown(global)) {
    return RightColumnContent.MessageStatistics
  } else if (selectIsStatisticsShown(global)) {
    return RightColumnContent.Statistics
  } else if (selectCurrentStickerSearch(global).query !== undefined) {
    return RightColumnContent.StickerSearch
  } else if (selectCurrentGifSearch(global).query !== undefined) {
    return RightColumnContent.GifSearch
  } else if (global.newChatMembersProgress !== NewChatMembersProgress.Closed) {
    return RightColumnContent.AddingMembers
  } else if (global.changeGroupUserPermissionsType !== ChangeGroupUserPermissionsType.Closed) {
    return RightColumnContent.ChangeGroupUserPermissions
  } else if (global.isChatInfoShown && selectCurrentMessageList(global)) {
    // debugger
    return RightColumnContent.ChatInfo
  } else {
    return undefined
  }
  // return selectIsPollResultsOpen(global) ? (
  //   RightColumnContent.PollResults
  // ) : !IS_SINGLE_COLUMN_LAYOUT && selectCurrentTextSearch(global) ? (
  //   RightColumnContent.Search
  // ) : selectCurrentManagement(global) ? (
  //   RightColumnContent.Management
  // ) : selectIsMessageStatisticsShown(global) ? (
  //   RightColumnContent.MessageStatistics
  // ) : selectIsStatisticsShown(global) ? (
  //   RightColumnContent.Statistics
  // ) : selectCurrentStickerSearch(global).query !== undefined ? (
  //   RightColumnContent.StickerSearch
  // ) : selectCurrentGifSearch(global).query !== undefined ? (
  //   RightColumnContent.GifSearch
  // ) : global.newChatMembersProgress !== NewChatMembersProgress.Closed ? (
  //   RightColumnContent.AddingMembers
  // ) : global.isChatInfoShown && selectCurrentMessageList(global) ? (
  //   RightColumnContent.ChatInfo
  // ) : undefined;
}

export function selectIsRightColumnShown(global: GlobalState) {
  return selectRightColumnContentKey(global) !== undefined;
}

export function selectTheme(global: GlobalState) {
  const { theme, shouldUseSystemTheme } = global.settings.byKey;

  return shouldUseSystemTheme ? getSystemTheme() : theme;
}
