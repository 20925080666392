
import { withGlobal } from '../../global';
import React, {
    useState,
    useRef,
    useCallback,
    useEffect
} from '../../lib/teact/teact';
import type { FC } from '../../lib/teact/teact';
import { AppInfoProps } from './AuthUserNameLogin';
import './AuthVerifyLogin.scss';
import LogoImage from '../../assets/logo.png';
import { getDefaultAppInfo } from '../../util/toolUtils';
import wait from '../../assets/wait.png';
import worning from '../../assets/worning.png';
interface StateProps {
    language: string;
    verifyState: number;
};


const AuthVerifyLogin: FC<StateProps> = (props) => {
    const { language, verifyState } = props;
    const [appInfo, setAppInfo] = useState<AppInfoProps>();

    useEffect(() => {
        const gAppInfo = localStorage.getItem('appInfo');
        const languageValue = language || localStorage.getItem('lang');
        if (gAppInfo) {
            const gAppInfoMap = JSON.parse(gAppInfo);
            let showAppInfo: AppInfoProps = {
                merchantLogoUrl: gAppInfoMap.merchantLogoUrl,
                merchantName: '',
                autofillInviteCode: gAppInfoMap.autofillInviteCode,
                isAutofillInviteCode: gAppInfoMap.isAutofillInviteCode
            }
            switch (languageValue) {
                case 'classic-zh-cn':
                    showAppInfo.merchantName = gAppInfoMap.merchantName;
                    break;
                case 'zh-hant-raw':
                    showAppInfo.merchantName = gAppInfoMap.merchantNameZhTra;
                    break;
                case 'en':
                    showAppInfo.merchantName = gAppInfoMap.merchantNameEn;
                    break;
                default:
                    showAppInfo.merchantName = gAppInfoMap.merchantName;
            }
            setAppInfo(showAppInfo);
        } else {
            setDefaultAppInfo();
        }
    }, [language]);

    const setDefaultAppInfo = async () => {
        const defaultAppInfo = await getDefaultAppInfo(window.location.host);
        setAppInfo(defaultAppInfo);
    }
    let logoBgCss = verifyState === 3 ? `background-image: url(${worning})` : `background-image: url(${wait})`;
    // if (appInfo && appInfo.merchantLogoUrl) {
    //     logoBgCss = `background-image: url(${appInfo.merchantLogoUrl})`
    // } else {
    //     logoBgCss = `background-image: url(${LogoImage})`
    // }
    return (
        <div id="auth-phone-number-form" className="custom-scroll">
            <div className="auth-form">
                <div id="logo" className={'logo'} style={logoBgCss} />
                {
                    verifyState === 3 ? <p className='reject'>管理员拒绝了您的访问</p> : <>
                        <h1>请不要离开当前页面</h1>
                        <p>管理员正在操作您的访问请求</p>
                    </>
                }
            </div>
        </div >
    )
}

export default withGlobal(
    (global): StateProps => {
        const {
            verifyState
        } = global;
        const language = global.settings.byKey.language;
        console.log(verifyState)
        return {
            language,
            verifyState
        };

    },
)(AuthVerifyLogin);
